import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeUser, updateUser } from "../redux/users/usersSlice";
// import { api } from "../helpers/apiHelper/requestHelper";
import LoadingModal from "../components/loader/loadingModal";
import AuthLoader from "../components/loader/authLoader";
import useApi from "../helpers/apiHelper/requestHelper";
import {
  setLoading,
  setSigningYouIn,
  setShowConnectionSuccessModal,
  setShowConnectionFailModal,
} from "../redux/loading/loadingSlice";
import { useTour } from "@reactour/tour";
import { setSidebar } from "../redux/sidebar/sidebarSlice";
import { setAllLinks } from "../redux/links/linksSlice";
import { setCurrentTab } from "../redux/links/linksSlice";
import { socailSignUp } from "../redux/socialSignup/socialSignUp";
import SideBar from "./SideBar";

export const UserLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [onboard, setOnboard] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [tradeSubmenuVisible, setTradeSubmenuVisible] = useState(false);
  const [performanceSubmenueVisible, setPerformanceSubmenueVisible] =
    useState(false);
  const [settingSubmenueVisible, setSettingSubmenueVisible] = useState(false);
  const [tradingSubmenuVisible, setTradingSubmenuVisible] = useState(false);
  const [businessSubmenuVisible, setBusinessSubmenuVisible] = useState(false);
  const [appSubmenuVisible, setAppSubmenuVisible] = useState(false);

  const user = useSelector((e) => e?.user?.user);
  const loaders = useSelector((state) => state?.loader);
  const { currentTab } = useSelector((state) => state.links);

  const openSidebar = useSelector((state) => state?.sidebar?.openSidebar);
  const showSidebar = useSelector((state) => state.sidebar?.showSidebar);
  const api = useApi();
  const { isOpen, setIsOpen, currentStep, setCurrentStep } = useTour();

  const tourNavigate = localStorage.getItem("tour");

  const tourClose = localStorage.getItem("tourClose");

  const [isBrave, setIsBrave] = useState(false);

  useEffect(() => {
    const checkBrave = async () => {
      if (navigator.brave && (await navigator.brave.isBrave())) {
        setIsBrave(true);
      }
    };

    checkBrave();
  }, []);

  useEffect(() => {
    if (tourNavigate) {
      if (user?.role === "leader") {
        navigate("/leader/copyTrade", { replace: true });
      } else if (user?.role === "member") {
        navigate("/member/copyTrade", { replace: true });
      } else if (user?.role === "admin") {
        navigate("/admin/copyTrade");
      }
      localStorage.removeItem("tour");
    }
  }, [tourNavigate]);

  useEffect(() => {
    if (onboard) {
      if (user?.role === "leader") {
        navigate("/leader/copyTrade");
      } else if (user?.role === "member") {
        if (user?.firstVisit == true && user?.onboarding?.payment == true) {
          navigate("/member/tradeActive", { replace: true });
        } else {
          navigate("/member/copyTrade", { replace: true });
          dispatch(setCurrentTab("introduction"));
        }
      } else if (user?.role === "admin") {
        navigate("/admin/copyTrade");
      }
      setOnboard(false);
    }
  }, [onboard]);

  useEffect(() => {
    dispatch(setLoading(true));
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
    dispatch(setLoading(false));
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (!user) {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
      navigate("/login");
    } else {
      checkRoutes();
    }
  }, [user, navigate]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setSigningYouIn(false));
    }, 5000);

    // Cleanup function to clear the timeout on unmount or re-render
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Check the screen size on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const checkRoutes = () => {
    let role = user?.role;
    let route = window.location.pathname;
    if (
      role === "leader" &&
      (route.includes("admin/") || route.includes("member/"))
    ) {
      navigate("/leader/dashboard", { replace: true });
    } else if (
      role === "member" &&
      (route.includes("admin/") || route.includes("leader/"))
    ) {
      navigate("/member/dashboard", { replace: true });
    } else if (
      role === "admin" &&
      (route.includes("member/") || route.includes("leader/"))
    ) {
      navigate("/admin/dashboard", { replace: true });
    }
  };

  useEffect(() => {
    fetchUserData();
    checkRoutes();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  }, [window.location.pathname]);

  useEffect(() => {
    getLinks();
  }, []);

  useEffect(() => {}, [loaders]);

  const getLinks = async () => {
    const response = await api("get", "links/index");
    if (response?.status) {
      dispatch(setAllLinks(response?.data));
    } else {
      navigate("login");
      // toastify(response?.message);
    }
  };

  const fetchUserData = async () => {
    const response = await api("get", "users/index");
    if (response?.status) {
      dispatch(storeUser(response?.data));
      localStorage.setItem("token", response?.data?.token);
    }
  };

  const checkConnected = () => {
    const isBinanceConnected = user?.binanceCredentials?.isConnected;
    const isBybitConnected = user?.bybitCredentials?.isConnected;

    if (isBinanceConnected && isBybitConnected) {
      return (
        <p className="fs18 text_GB text-700">
          CONNECTED:{" "}
          <span className="span-text text_dark6">Binance | Bybit</span>
        </p>
      );
    } else if (isBinanceConnected) {
      return (
        <p className="fs18 text_GB text-700">
          CONNECTED: <span className="span-text text_dark6">Binance</span>
        </p>
      );
    } else if (isBybitConnected) {
      return (
        <p className="fs18 text_GB text-700">
          CONNECTED: <span className="span-text text_dark6">Bybit</span>
        </p>
      );
    } else {
      return <p className="fs18 text_GB text-700">DISCONNECTED</p>;
    }
  };

  const checkConnection = () => {
    const isBinanceConnected = user?.binanceCredentials?.isConnected;
    const isBybitConnected = user?.bybitCredentials?.isConnected;

    if (isBinanceConnected || isBybitConnected) {
      return true;
    } else {
      return false;
    }
  };

  const openSidebarAutomatically = () => {
    dispatch(setSidebar(true));
  };

  useEffect(() => {
    if (user) {
      checkConnected();
      checkConnection();
    }
    if (user?.firstVisit && !tourClose) {
      openSidebarAutomatically();
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    if (loaders?.showConnectionSuccessModal) {
      setTimeout(() => {
        dispatch(setShowConnectionSuccessModal(false));
      }, 3000);
    }
  }, [loaders?.showConnectionSuccessModal]);

  return (
    <>
      {/* <Header /> */}
      {loaders?.isLoading && <LoadingModal />}
      {loaders?.settingUpService && (
        <AuthLoader component="settings" user={user} />
      )}
      {loaders?.signingYouIn && <AuthLoader />}
      {/* {loaders?.showConnectionSuccessModal && <AuthLoader component="connectionsuccess" />} */}
      {/* {loaders?.showConnectionFailModal && <AuthLoader component="connectionfail" handleErrorModal={handleErrorModal} />} */}

      <section className="main_body common_style" style={{ zIndex: -1 }}>
        <div className="container mw-100">
          <div className="row">
            <SideBar />
            {/* outletLayout */}
            <div
              className={`col-xl-${
                (!showSidebar && currentTab !== "subscribe") ||
                (!showSidebar && location.pathname === "/member/tradeActive")
                  ? 8
                  : !showSidebar && currentTab === "subscribe"
                  ? 9
                  : 10
              } col-md-9 col-lg-9 ${
                user?.firstVisit ? "mx-auto" : ""
              } px-sm-3 px-md-0 px-lg-2 px-xl-5 ps-xl-5 ${
                isMobile ? "" : "layoutTop"
              }`}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
