import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Subscription from "./Subscription";
import Connection from "./Connection";
import Payment from "./Payment";
import Url from "./Url";
import OnboardingUser from "./OnboardingUser";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import CheckMark from "../../../assets/images/check.svg";
import { setResponseLoading } from "../../../redux/loading/loadingSlice";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setEditable, updateUser } from "../../../redux/users/usersSlice";

function Index() {
  // const [currentTab, setCurrentTab] = useState("subscription");

  const user = useSelector((value) => value?.user?.user);
  const loaders = useSelector((state) => state?.loader);
  const { currentTab } = useSelector((state) => state.links);

  const { id } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const api = useApi();

  useEffect(() => {
    const binance = localStorage.getItem("binanceCondition");
    if (binance) {
      localStorage.removeItem("binanceCondition");
      dispatch(
        updateUser({
          ...user,
          binanceCredentials: {
            ...user.binanceCredentials,
            isConnected: false,
          },
        })
      );
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [user]);

  useEffect(() => {
    if (id) {
      setCurrentTab("payment");
    }
  }, [id]);

  useEffect(() => {
    if (state) {
      const { tab, connect } = state;

      if (tab) {
        setCurrentTab(tab);
      }

      if (connect) {
        dispatch(setResponseLoading(true));
      }
    }
  }, [state]);

  const handleTabSelect = (selectedTab) => {
    if (
      (selectedTab === "connection" && !user?.onboarding?.subscribed) ||
      (selectedTab === "payment" && !user?.onboarding?.connect) ||
      (selectedTab === "onboarding" && !user?.onboarding?.payment) ||
      (selectedTab === "url" && user?.firstVisit)
    ) {
      // If the conditions are not met, return early
      return;
    }

    // Update the current tab
    setCurrentTab(selectedTab);
  };

  const handleTabChange = async (tab) => {
    dispatch(setCurrentTab(tab));
    if (user?.firstVisit) {
      await api("post", "users/update", {
        onboardingStep: tab,
      });
    }
  };

  useEffect(() => {
    dispatch(setEditable(true));
  }, [currentTab]);

  useEffect(() => {
    if (
      !["subscription", "connection", "onboarding", "url", "payment"].includes(
        currentTab
      )
    ) {
      dispatch(setCurrentTab("subscription"));
    }
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center support_wrapper h-100">
        <div className=" ">
          <div className="copyTrade mx-auto">
            <Tabs
              defaultActiveKey="subscription"
              id="uncontrolled-tab-example"
              className="copyTradeTab"
              activeKey={currentTab}
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey="subscription"
                // title={
                //     <div className="d-flex align-items-center">
                //         {user?.onboarding?.subscribed && (
                //             <img
                //                 className="me-1"
                //                 src={CheckMark}
                //                 alt="Subscription"
                //             />
                //         )}{" "}
                //         Subscription
                //     </div>
                // }
              >
                <Subscription setTab={handleTabChange} tab={currentTab} />
              </Tab>
              <Tab
                eventKey="connection"
                // title={
                //     <div className="d-flex align-items-center">
                //         {user?.onboarding?.connect && (
                //             <img
                //                 className="me-1"
                //                 src={CheckMark}
                //                 alt="Connection"
                //             />
                //         )}{" "}
                //         Connection
                //     </div>
                // }
                disabled={!user?.onboarding?.subscribed}
              >
                <Connection setTab={handleTabChange} />
              </Tab>
              <Tab
                eventKey="payment"
                // title={
                //     <div className="d-flex align-items-center">
                //         {user?.onboarding?.payment && (
                //             <img
                //                 className="me-1"
                //                 src={CheckMark}
                //                 alt="Payment"
                //             />
                //         )}{" "}
                //         Payment
                //     </div>
                // }
                // disabled={!user?.onboarding?.connect}
              >
                <Payment setTab={handleTabChange} />
              </Tab>
              <Tab
                eventKey="onboarding"
                // title={
                //     <div className="d-flex align-items-center">
                //         {!user?.firstVisit && (
                //             <img
                //                 className="me-1"
                //                 src={CheckMark}
                //                 alt="Onboarding Users"
                //             />
                //         )}{" "}
                //         Onboarding
                //     </div>
                // }
                // disabled={!user?.onboarding?.payment}
              >
                <OnboardingUser setTab={handleTabChange} />
              </Tab>
              <Tab
                eventKey="url"
                // title={
                //     <div className="d-flex align-items-center">
                //         {!user?.firstVisit && (
                //             <img
                //                 className="me-1"
                //                 src={CheckMark}
                //                 alt="Url"
                //             />
                //         )}{" "}
                //         URL
                //     </div>
                // }
                // disabled={user?.firstVisit}
              >
                <Url />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
