import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MembersOverview from "./MembersOverview";
import Message from "./Message";
function Index() {
  const [currentTab, setCurrentTab] = useState("membersOverviews");
  const [user, setUser] = useState(null);

  const handleTabSelect = (selectedTab) => {
    // Update the current tab
    setUser(null);
    setCurrentTab(selectedTab);
  };
  useEffect(() => {
    if (currentTab == "membersOverviews") {
      setUser(null);
    }
  }, []);
  return (
    <div className="container mt-sm-30 pt-4">
      <div className="row justify-content-center h-100 support_wrapper">
        <div
          className={`${
            currentTab == "membersOverviews" ? "w-915" : "copyTrade"
          }`}
        >
          <Tabs
            defaultActiveKey="membersOverviews"
            id="uncontrolled-tab-example"
            className="copyTradeTab border-bottom-ontab MemberTab"
            activeKey={currentTab}
            onSelect={handleTabSelect}
          >
            <Tab eventKey="membersOverviews" title="Members">
              <MembersOverview setTab={setCurrentTab} setUser={setUser} />
            </Tab>
            <Tab eventKey="message" title="Message">
              <Message
                setTab={setCurrentTab}
                user={user}
                setUser={setUser}
                currentTab={currentTab}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Index;
