import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/close_danger.svg";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
// import { api } from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { missingPopup } from "../../helpers/dataHelper/missingData";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import { connect, useDispatch, useSelector } from "react-redux";
import useApi from "../../helpers/apiHelper/requestHelper";
import { storeUser, updateUser } from "../../redux/users/usersSlice";
import Button from "../../components/button/Button";
import successGif from "../../assets/images/success.gif";
import {
  setLoading,
  setResponseLoading,
  setSettingUpService,
  setSigningYouIn,
  setShowConnectionSuccessModal,
  setShowConnectionFailModal,
  setConnectionFailMessage,
  setBinanceConnecting,
} from "../../redux/loading/loadingSlice";
import { useAnimate, usePresence, stagger } from "framer-motion";
import InputComponent from "../../components/input/InputComponent";
import { Form } from "react-bootstrap";
import { setShowSidebar } from "../../redux/sidebar/sidebarSlice";
import { setCurrentTab } from "../../redux/links/linksSlice";

const handleClick = () => {
  window.scrollTo(0, 0);
};

export default function Signin() {
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const { state: signUpLocation } = useLocation();
  const [password, setPassword] = useState("");
  const { id } = useParams();
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberMe")
  );
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();
  const [focusedInput, setFocusedInput] = useState(null);
  const [verify, setVerify] = useState(false);
  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();
  const [otp, setOtp] = useState("");
  const [data, setData] = useState({});
  const [socialLoader, setSocialLoader] = useState(null);
  let loginWithBinanceSuccess = false;
  const [codeIncorrect, setCodeIncorrect] = useState(false);
  const [communityState, setCommunityState] = useState(location?.state);
  const numberRegex = new RegExp(`^[0-9]*$`);
  const loaders = useSelector((state) => state?.loader);
  const socialSignUp = useSelector((state) => state?.socialSignUp?.value);

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };

  // useEffect(() => {
  //     localStorage.removeItem("token");
  //     localStorage.removeItem("lastVisitedRoute");
  //     dispatch(
  //         storeUser({
  //             user: null,
  //             token: null,
  //         })
  //     );
  //     dispatch(setLoading(false));
  // }, []);
  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get("state");
  const code = urlParams.get("code");

  const handleSocialLogin = async () => {
    if (code && state === "undefined") {
      if (user.role === "leader")
        navigate("/leader/copyTrade", {
          state: { tab: "connection", connect: true },
          replace: true,
        });
      else if (user.role === "member")
        navigate("/member/copyTrade", {
          state: { tab: "connection", connect: true },
          replace: true,
        });
      dispatch(setLoading(true));
      dispatch(setResponseLoading(true));

      try {
        const response = await api("post", "users/loginWithByBit", {
          exchange: "Binance",
          code: code,
        });
        if (response?.status) {
          // toastify("Binance connected successfully!", "success");
          dispatch(updateUser(response?.data));
          dispatch(setShowConnectionSuccessModal(true));
          dispatch(setResponseLoading(false));
          dispatch(setBinanceConnecting(false));
          setTimeout(async () => {
            if (user?.role === "member") {
              dispatch(setCurrentTab("subscribe"));
              await api("post", "users/update", {
                onboardingStep: "subscribe",
              });
            }
            if (user?.role === "leader") {
              dispatch(setCurrentTab("payment"));
              await api("post", "users/update", {
                onboardingStep: "payment",
              });
            }
          }, 2000);

          // else if (user.role === "admin")
          //     navigate("/admin/copyTrade", {
          //         state: { tab: "connection" },
          //     });
        } else {
          dispatch(setLoading(false));
          dispatch(setResponseLoading(false));
          dispatch(setConnectionFailMessage(response?.message));
          // toastify(response?.message);
          dispatch(setShowConnectionFailModal(true));
          dispatch(setBinanceConnecting(false));
        }
      } catch (error) {
        dispatch(setLoading(false));
        dispatch(setResponseLoading(false));
        console.error("Error while calling loginWithByBit:", error);
      }
    } else if (code && state !== "undefined") {
      dispatch(setLoading(true));
      try {
        const response = await api("post", "social/loginWithTwitter", {
          code: code,
          state: state,
        });
        if (response?.status) {
          dispatch(updateUser(response?.data));
          dispatch(setLoading(false));
          toastify(response?.message, "success");
          if (user?.role === "leader")
            navigate("/leader/performance", {
              state: { tab: "autoShare" },
            });
          else if (user?.role === "member")
            navigate("/member/dashboard", { replace: true });
        } else {
          dispatch(setLoading(false));
          toastify(response?.message);
        }
      } catch (error) {
        dispatch(setLoading(false));
        console.error("Error while calling loginWithTwitter:", error);
      }
    }
  };

  const loginWithSocialSuccess = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    const quickSocialLogin = JSON.parse(
      localStorage.getItem("AllowSocialLogin")
    );
    if (user) {
      if (!socialSignUp?.connecting) {
        await handleSocialLogin();
      }
      if (user.role === "leader") {
        if (code && state !== "undefined") {
          navigate("/leader/performance", {
            state: { tab: "autoShare" },
          });
        } else if (code && state === "undefined") {
          navigate("/leader/copyTrade", {
            state: {
              tab: "connection",
              connect: false,
              // binance: true,
            },
          });
        }
      } else if (user.role === "member") {
        if (code && state === "undefined") {
          navigate("/member/copyTrade", {
            state: {
              tab: "connection",
              connect: false,
              // binance: true,
            },
            replace: true,
          });
        }
      }
    } else if (!user && loaders?.binanceConnecting && quickSocialLogin) {
      dispatch(setLoading(true));
      const urlParams = new URLSearchParams(window.location.search);
      const state = urlParams.get("state");
      const code = urlParams.get("code");
      setTimeout(() => {
        dispatch(setResponseLoading(true));
        dispatch(setLoading(true));
      }, 0);
      try {
        const response = await api("post", "social/socialLogin", {
          exchange: "Binance",
          code: code,
          role: socialSignUp?.role,
          ref: socialSignUp?.community,
          name: socialSignUp?.businessName,
          termsConditions: true,
          acceptMarketing: true,
        });
        setTimeout(() => {
          //we set loader below but they didn't work
          dispatch(setLoading(false));
          dispatch(setResponseLoading(false));
        }, 0);
        if (response?.status) {
          localStorage.setItem("token", response?.data?.token);
          // dispatch(setBinanceConnecting(false))
          dispatch(storeUser(response?.data));
          if (!response?.data?.user?.binanceLogin?.emailVerified) {
            loginWithBinanceSuccess = true;
            setTimeout(() => {
              dispatch(setLoading(false));
              dispatch(setResponseLoading(false));
            }, 0);
            if (response?.data?.user?.role == "member")
              navigate("/member/emailGetter", { replace: true });
            else navigate("/leader/emailGetter", { replace: true });
          } else {
            navigate("/member/dashboard", { replace: true });
          }
        }
        if (!response?.status) {
          localStorage.removeItem("AllowSocialLogin");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    loginWithSocialSuccess();
  }, [window.location, user]);

  // const handleUseEffect = async () => {
  //     if (user) {
  //         const urlParams = new URLSearchParams(window.location.search);
  //         const code = urlParams.get("code");
  //         if (localStorage.getItem("rememberMe") || code) {
  //             if (code) {
  //                 if (user?.role === "leader") {
  //                     navigate("/leader/copyTrade", {
  //                         state: { tab: "connection" },
  //                     });
  //                 } else if (
  //                     user?.role === "member" &&
  //                     user?.onboarding?.connect
  //                 )
  //                     navigate("/member/dashboard");
  //             } else {
  //                 if (user?.role === "leader") navigate("/leader/dashboard");
  //                 else if (
  //                     user?.role === "member" &&
  //                     user?.onboarding?.connect
  //                 )
  //                     navigate("/member/dashboard");
  //             }
  //         } else {
  //             localStorage.setItem(
  //                 "lastVisitedRoute",
  //                 window.location.pathname
  //             );
  //             localStorage.removeItem("token");
  //             dispatch(
  //                 storeUser({
  //                     user: null,
  //                     token: null,
  //                 })
  //             );
  //         }
  //     }
  // };

  // useEffect(() => {
  //     handleUseEffect();
  // }, [window.location, user]);

  useEffect(() => {
    const enterAnimation = async () => {
      await animate(
        scope.current,
        {
          x: verify ? [300, 0] : [-300, 0], // Updated for verify state
          // opacity: [0, 1],
          delay: stagger(0.1, { ease: "easeOut" }),
        },
        { duration: 0.2 }
      );
    };

    const exitAnimation = async () => {
      await animate(
        scope.current,
        {
          x: verify ? [0, -300] : [0, 300], // Updated for verify state
          // opacity: [1, 0],
          delay: stagger(0.1, { ease: "easeOut" }),
        },
        { duration: 0.2 }
      );
      safeToRemove();
    };
    // Avoid "Invalid element" error on back button click by hiding enter/exit animations
    // if (isPresent) {
    //     enterAnimation();
    // } else {
    //     exitAnimation();
    // }
  }, [scope, isPresent, verify]);

  const handleSignin = async (event) => {
    event?.preventDefault();
    if (!email) {
      // dispatch(setLoading(false));
      missingPopup("Email");
    } else if (!password) {
      missingPopup("Password");
    } else {
      let data = { email, password };
      if (communityState?.community?._id) {
        data = { ...data, communityId: communityState?.community?._id };
      }
      const response = await api("post", "auth/login", data);

      if (response?.status) {
        if (response?.data?.user) {
          dispatch(setSigningYouIn(true));
          localStorage.setItem("token", response?.data?.token);
          if (!response?.data.user.firstVisit) {
            dispatch(setShowSidebar(true));
          } else {
            dispatch(setShowSidebar(false));
          }
          dispatch(storeUser(response?.data));
          // toastify("Email verified successfully.", "success");
          if (response?.data?.user?.role === "member") {
            const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");

            setTimeout(() => {
              dispatch(setSigningYouIn(false));
            }, 7000);

            if (
              lastVisitedRoute &&
              lastVisitedRoute?.includes("member") &&
              !response?.data?.user?.firstVisit
            ) {
              localStorage.removeItem("lastVisitedRoute");
              navigate(lastVisitedRoute);
            } else {
              if (response?.data?.user?.firstVisit) {
                const { onboardingStep } = response?.data?.user;
                if (response?.data?.user?.onboarding?.payment == true) {
                  navigate("/member/tradeActive", { replace: true });
                } else navigate("/member/copyTrade", { replace: true });
                if (onboardingStep) {
                  dispatch(setCurrentTab(onboardingStep));
                }
              } else {
                navigate("/member/dashboard", { replace: true });
              }
            }
          } else if (response?.data?.user?.role === "leader") {
            const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");

            setTimeout(() => {
              dispatch(setSigningYouIn(false));
            }, 7000);
            if (
              lastVisitedRoute &&
              lastVisitedRoute?.includes("leader") &&
              !response?.data?.user?.firstVisit
            ) {
              localStorage.removeItem("lastVisitedRoute");
              navigate(lastVisitedRoute);
            } else {
              if (response?.data?.user?.firstVisit) {
                const { onboardingStep } = response?.data?.user;
                navigate("/leader/copyTrade", { replace: true });
                if (onboardingStep) {
                  dispatch(setCurrentTab(onboardingStep));
                } else {
                  dispatch(setCurrentTab("subscription"));
                }
              } else {
                navigate("/leader/dashboard", { replace: true });
              }
            }
          } else {
            const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");

            setTimeout(() => {
              dispatch(setSigningYouIn(false));
            }, 7000);

            if (lastVisitedRoute && lastVisitedRoute?.includes("admin")) {
              localStorage.removeItem("lastVisitedRoute");
              navigate(lastVisitedRoute);
            } else {
              navigate("/admin/dashboard", { replace: true });
            }
          }
        } else {
          const communityId = communityState?.community?._id;
          setVerify(true);
          setData({ email, password, communityId });
          // navigate("/login/verifyEmail", {
          //     state: { email, password, communityId },
          // });
        }
      } else {
        setCommunityState(null);
        toastify(response?.message);
      }
    }
  };

  const handleResendCode = async () => {
    const response = await api("post", "auth/login", data);

    if (response?.status) {
      toastify(response?.message, "success");
    }
  };

  const handleVerify = async () => {
    if (!otp || otp.length < 6) {
      // toastify("Please enter a 6 digit Code sent to your email address.");
      setCodeIncorrect(true);
    } else {
      dispatch(setSigningYouIn(true));
      const response = await api("post", "auth/verifyEmailOtp", {
        email: data?.email,
        otp,
      });
      if (response?.status) {
        setTimeout(() => {
          dispatch(setSigningYouIn(false));
        }, 5000);
        // toastify("Email verified successfully.", "success");
        if (communityState?.resetPass) {
          navigate("/login/createPassword", {
            state: { email: data?.email },
          });
        } else {
          dispatch(storeUser(response?.data));
          localStorage.setItem("token", response?.data?.token);
          if (response?.data?.user?.role === "member") {
            const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
            if (lastVisitedRoute && lastVisitedRoute?.includes("member")) {
              localStorage.removeItem("lastVisitedRoute");
              navigate(lastVisitedRoute);
            } else {
              navigate("/member/dashboard", { replace: true });
              if (!response?.data.user.firstVisit) {
                dispatch(setShowSidebar(true));
              }
            }
          } else if (response?.data?.user?.role === "leader") {
            const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
            if (lastVisitedRoute && lastVisitedRoute?.includes("leader")) {
              localStorage.removeItem("lastVisitedRoute");
              navigate(lastVisitedRoute);
            } else {
              navigate("/leader/dashboard", { replace: true });
              if (!response?.data.user.firstVisit) {
                dispatch(setShowSidebar(true));
              }
            }
          } else if (response?.data?.user?.role === "admin") {
            const lastVisitedRoute = localStorage.getItem("lastVisitedRoute");
            if (lastVisitedRoute && lastVisitedRoute?.includes("admin")) {
              localStorage.removeItem("lastVisitedRoute");
              navigate(lastVisitedRoute);
            } else {
              navigate("/admin/dashboard", { replace: true });
            }
          }
        }
      } else {
        dispatch(setSigningYouIn(false));
        dispatch(setLoading(false));
        toastify(response?.message);
      }
    }
  };

  useEffect(() => {
    if (
      user?.role === "member" &&
      user?.firstVisit &&
      !socialSignUp?.connecting
    ) {
      if (user?.onboarding?.payment) {
        navigate("/member/tradeActive", { replace: true });
      } else navigate("/member/copyTrade", { replace: true });
    } else if (user?.role === "leader" && user?.firstVisit) {
      navigate("/leader/copyTrade", { replace: true });
    }
    if (!user) {
      //  navigate('/leader/dashboard')
    } else {
      if (user?.role === "leader" && !user?.firstVisit && !code && !state) {
        navigate("/leader/dashboard", {
          replace: true,
          state: { tab: "subscription" },
        });
      } else if (user?.role === "member" && !user?.firstVisit) {
        navigate("/member/dashboard", {
          replace: true,
          state: { tab: "subscription" },
        });
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (otp?.length === 6) {
      handleVerify();
    }
  }, [otp]);

  return (
    <>
      {!user && !loaders?.awaitingResponse && !socialSignUp?.connecting && (
        <div className="common_style">
          <Header />
          {/* <p className="my-5 text-center">asdsadsadasdasdsa dasdasdsad</p> */}
          <section className="sign_section d-flex align-items-center">
            {verify ? (
              <div className="login_card account_card">
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-sm-12 px-0">
                      <div className="d-flex justify-content-between flex-column">
                        <div>
                          <h2 className="fs20 li_h30 text_GB mb-9">
                            Check your email{" "}
                          </h2>
                          <h5 className="text-center fs14 mb-26 regular">
                            Use the code sent to your email on it
                          </h5>
                          <Form.Group ref={scope} controlId="formOtp">
                            <InputComponent
                              type="text"
                              label="Paste code here"
                              focusedInput={otp ? "focused" : ""}
                              handleFocus={handleFocus}
                              value={otp}
                              maxLength={6}
                              onChange={(inputValue) => {
                                if (
                                  numberRegex.test(inputValue) ||
                                  inputValue === ""
                                ) {
                                  setOtp(inputValue);
                                }
                              }}
                            />
                            {codeIncorrect && (
                              <p className="text-danger fs15">Code incorrect</p>
                            )}
                          </Form.Group>
                        </div>
                        <div className="">
                          <div className="mt-19">
                            <button
                              className="newCreateButton"
                              onClick={handleVerify}
                            >
                              Verify Email
                            </button>
                          </div>
                          <div className="mt-12">
                            <Link
                              className="newSignLink"
                              onClick={handleResendCode}
                            >
                              Resend Code
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login_card account_card">
                <form className="container" onSubmit={handleSignin}>
                  <div className="row justify-content-center">
                    <div className="col-sm-12 px-0">
                      <div className="">
                        <h2 className="fs20 li_h30 text_GB mb-9">Sign in</h2>
                        <div className="mb-26 height-14px"></div>
                      </div>
                      <div ref={scope}></div>
                      <div className="pt-1">
                        <div className="pb-13">
                          <InputComponent
                            type="email"
                            label="Email"
                            focusedInput={focusedInput}
                            handleFocus={handleFocus}
                            handleBlur={handleBlur}
                            value={email}
                            onChange={setEmail}
                          />
                        </div>
                        <div className="">
                          <InputComponent
                            type="password"
                            label="Password"
                            focusedInput={focusedInput}
                            handleFocus={handleFocus}
                            handleBlur={handleBlur}
                            value={password}
                            onChange={setPassword}
                            autoComplete="off"
                          />
                        </div>
                      </div>

                      <div className="pt-14 d-flex align-items-center justify-content-between">
                        <label
                          className="fs15 d-flex align-items-center"
                          role="button"
                        >
                          <input
                            type="checkbox"
                            className="me-2"
                            checked={rememberMe}
                            onChange={() => {
                              const newValue = !rememberMe;
                              setRememberMe(newValue);

                              if (newValue) {
                                localStorage.setItem("rememberMe", "true");
                              } else {
                                localStorage.removeItem("rememberMe");
                              }
                            }}
                          />
                          <span className="remember-me text_GR">
                            Remember me
                          </span>
                        </label>
                        <a
                          onClick={() => navigate("forgotPassword")}
                          className="fs14 text-dark text_GM"
                        >
                          Forgot password?
                        </a>
                      </div>
                      <div className="mt-123">
                        <button
                          className="newCreateButton"
                          onClick={handleSignin}
                        >
                          Sign In
                        </button>
                        <div className="mt-12">
                          <a
                            onClick={() => {
                              if (communityState?.community) {
                                navigate("/member/signup", {
                                  state: communityState?.community,
                                });
                              } else
                                navigate(
                                  "/signup"
                                  // state?.leader
                                  //     ? "leader/signup"
                                  //     : "member/signup"
                                );
                              handleClick();
                            }}
                            className="newSignLink "
                          >
                            Sign up instead
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </section>

          <div
            className="modal fade"
            id="SIGNIN"
            tabIndex="-1"
            aria-labelledby="SIGNINLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-sm modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body position-relative">
                  <img
                    src={closeIcon}
                    alt="img"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="position-absolute"
                    role="button"
                  />

                  <div className="ps-4">
                    <p className="fs16 text-700">Wrong password</p>
                    <p className="fs16">Sad smiley. Try one more time…</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      )}
    </>
  );
}
