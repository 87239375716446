import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import InputComponent from "../../../components/input/InputComponent";
import { useAnimate } from "framer-motion";
import useApi from "../../../helpers/apiHelper/requestHelper";
import Close from "../../../assets/images/green-corss.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export default function VipVerificationPopUp({ setShowVipPopUp, callApi }) {
  const [otp, setOtp] = useState(""); // OTP state
  const [codeIncorrect, setCodeIncorrect] = useState(false); // Incorrect code state
  const [focusedInput, setFocusedInput] = useState(null);
  const user = useSelector((state) => state?.user?.user);

  const api = useApi();

  const [scope, animate] = useAnimate();
  const numberRegex = new RegExp(`^[0-9]*$`);

  const handleVerify = async () => {
    if (!otp || otp.length < 6) {
      setCodeIncorrect({ show: true, message: "Code incorrect" });
    } else {
      const response = await api("post", "vipMember/verifyCode", {
        vipCode: otp,
        email: user?.email,
      });

      if (response?.data?.verified) {
        callApi();
        handleCLosePopUp();
      } else {
        setCodeIncorrect({ show: true, message: response?.message });
      }
    }
  };

  const handleCLosePopUp = () => {
    setShowVipPopUp({ show: false });
  };

  useEffect(() => {
    if (otp?.length === 6) {
      handleVerify();
    }
  }, [otp]);

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  return (
    <Modal show={true} onHide={handleCLosePopUp} className="otpModal">
      <Modal.Body>
        <Button className="OtpCloseBtn" onClick={handleCLosePopUp}>
          <img src={Close} alt="" />
        </Button>
        <div className="common_style">
          <h2 className="fs20 li_h30 text-center text_GB mb-9">
            Check your email
          </h2>
          <h5 className="text-center fs14 mb-26 regular mt-4">
            If you are a VIP member, your leader has sent you a verification
            code via email.
            <br />
            Please check your email, retrieve the code, and enter it below to
            verify the changes.
          </h5>

          <Form.Group ref={scope} controlId="formOtp">
            <InputComponent
              type="text"
              label="Paste you vip code here"
              focusedInput={otp ? "focused" : ""}
              handleFocus={handleFocus}
              value={otp}
              maxLength={6}
              onChange={(inputValue) => {
                if (numberRegex.test(inputValue) || inputValue === "") {
                  setOtp(inputValue);
                }
              }}
            />
            {codeIncorrect?.show && (
              <p className="text-danger fs15">{codeIncorrect?.message}</p>
            )}
          </Form.Group>
          <div className="">
            <div className="mt-19">
              <button className="newCreateButton" onClick={handleVerify}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
