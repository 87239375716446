import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import signImage from "../../../assets/images/quikpay.svg";
import {
  setShowSidebar,
  setSidebar,
} from "../../../redux/sidebar/sidebarSlice";
import { useEffect } from "react";
import { storeUser } from "../../../redux/users/usersSlice";
import money from "../../../assets/images/Money.svg";
import usingWarning from "../../../assets/images/user-error.png";
import exclaminatoion from "../../../assets/images/warning.png";

export default function TradeActive() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const api = useApi();
  const user = useSelector((state) => state?.user?.user);

  localStorage.removeItem("customerId");

  // useEffect(() => {
  //     const handleUpdate = async () => {
  //         const response = await api("post", "users/updateStatus", {
  //             // firstVisit: false,
  //             onboardingStep: ""
  //         });
  //     }
  //     handleUpdate()
  // }, []);

  const handleUpdate = async () => {
    const response = await api("post", "users/updateStatus", {
      firstVisit: false,
      onboardingStep: "",
    });

    if (response?.status) {
      dispatch(storeUser(response?.data));
      dispatch(setShowSidebar(true));
      dispatch(setSidebar(false));
      if (user?.role === "member") {
        navigate("/member/dashboard", { replace: true });
      } else {
        navigate("/leader/dashboard", { replace: true });
      }
    }
  };

  return (
    <>
      <div
        className="trade_active text-center"
        style={{ padding: "28px 36px 0 36px" }}
      >
        <section>
          <div>
            {/* {!user?.firstVisit && ( */}
            <h4 className="fs16 bold mb-28">
              {user?.role === "member"
                ? user?.memberCommunities[0]?.community?.name?.toUpperCase()
                : user?.leaderCommunity?.name?.toUpperCase()}
            </h4>
            {/* )} */}
          </div>
          <div className="mb-1">
            {user?.role === "member" ? (
              <h3 className="fs20 bold li_h30">
                Done! Now, a few house{" "}
                <br className="d-lg-none d-md-none d-block" /> rules before you
                start
              </h3>
            ) : (
              <h3 className="fs20 bold li_h30">
                AWESOME. YOUR COPY TRADING IS NOW ACTIVE
              </h3>
            )}
          </div>
          <div className="d-flex align-items-start justify-content-center">
            {user?.role === "member" ? (
              <div className="custom-trade-list">
                {/* <p className="fs14 mb-206">Ensure to have minimum <span style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Graphik Bold' }}>{user?.memberCommunities[0]?.community?.onboardingUser?.minFund} USDT</span> on your Binance <span style={{ fontWeight: '700', fontSize: '14px', fontFamily: 'Graphik Bold' }}>Futures USDT Perpetual account</span></p> */}
                <div className="active-trade-fix-width">
                  <p className="text-center text-lg-center text-md-center mt-2 mt-lg-0 mt-md-0">
                    Please read carefully
                  </p>{" "}
                  <br />
                  <div className="activetrade-text-item">
                    {/* <div className="img-box">
                                            <img src={money} alt="" className="margeintop-negtive" />
                                        </div> */}
                    <div className="content-box">
                      <div className="activetrade-header">
                        <div className="img-box">
                          <img src={money} alt="" />
                        </div>
                        <h4>Minimum funding</h4>
                      </div>
                      <p>
                        Keep your trading account funded with minimum{" "}
                        {
                          user?.memberCommunities[0]?.community?.onboardingUser
                            ?.minFund
                        }{" "}
                        USDT at all time.
                      </p>
                    </div>
                  </div>
                  <div className="activetrade-text-item">
                    {/* <div className="img-box">
                                            <img src={usingWarning} alt="" />
                                        </div> */}
                    <div className="content-box">
                      <div className="activetrade-header">
                        <div className="img-box">
                          <img src={usingWarning} alt="" />
                        </div>
                        <h4>Trading account to use</h4>
                      </div>
                      <p>
                        Binance Futures USDT Perpetual account. No funds here,
                        no copy trades.
                      </p>
                    </div>
                  </div>
                  <div className="activetrade-text-item border-0">
                    {/* <div className="img-box">
                                            <img src={exclaminatoion} alt="" />
                                        </div> */}
                    <div className="content-box">
                      <div className="activetrade-header">
                        <div className="img-box">
                          <img src={exclaminatoion} alt="" />
                        </div>
                        <h4 className="text-start">
                          Don’t trade on the connected account
                        </h4>
                      </div>

                      <p className="text-start">
                        Avoid trading on the connected trading account. It can
                        affect your copy trading negatively.
                      </p>
                    </div>
                  </div>
                  {/* <h4 className="trade_heading_center atention_sign">
                                        <span className="circle-dot"></span>
                                        <span className="text">
                                            Minimum funding
                                        </span>
                                    </h4>
                                    <p>
                                        Have minimum ${user?.memberCommunities[0]?.community?.onboardingUser?.minFund} on your trading account.
                                    </p> <br />
                                    <h4 className="trade_heading_center atention_sign">
                                        <span className="circle-dot"></span>
                                        <span className="text">
                                            Trading account to keep funds in
                                        </span>
                                    </h4>
                                    <p>
                                        Binance Futures USDT Perpetual account.
                                    </p> <br />
                                    <h4 className="trade_heading_center atention_sign">
                                        <span className="circle-dot"></span>
                                        <span className="text">
                                            Don’t trade on the connected account
                                        </span>
                                    </h4>
                                    <p>
                                        Avoid trading yourself on the connected exchange account as it may affect your copy trading negatively.
                                    </p> <br /> */}
                </div>
              </div>
            ) : (
              <div className="mb-5 text-center">
                <h4 className="trade_heading_center mb-3">
                  From now on what ever you trade <br /> your subscribers trade
                  automatically!
                </h4>
                <h4 className="trade_heading_center atention_sign">
                  <img src={signImage} alt="signImage" />
                  Enough funds on the exchange
                </h4>
                <p className="mb-3">
                  Inform your subscribers about having funds and how much as
                  minimum on their account.
                </p>
                <h4 className="trade_heading_center">Risk management</h4>
                <p className="mb-3">
                  Please advice your community on risk management.
                </p>
              </div>
            )}
          </div>
          <p className="trade-active-samll-text">
            I’ve read and understood the above.
          </p>
          <button
            style={{ width: "294px" }}
            className="newCreateButton"
            onClick={() => {
              handleUpdate(); // Call the handleUpdate function
            }}
          >
            Ok, got it
          </button>
        </section>
      </div>
    </>
  );
}
