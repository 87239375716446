import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import searchIcon from "../../../assets/images/search-icon.svg";
import {
  Accordion,
  Button,
  Dropdown,
  DropdownButton,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
// import { api } from "../../../helpers/apiHelper/requestHelper";
import { useDetectClickOutside } from "react-detect-click-outside";
// import Pagination from "../../../components/Pagination";
import xmark from "../../../assets/images/xmark.svg";
import { setLoading } from "../../../redux/loading/loadingSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import CustomPagination from "../../../components/CustomPagination";

export default function MemberTradeHistory() {
  const api = useApi();
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [communities, setCommunities] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [history, setHistory] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const ref = useDetectClickOutside({ onTriggered: () => setShow(false) });
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCommunities = async () => {
      const response = await api("get", "community/index");
      if (response?.status) {
        const userCommunityIds = user?.memberCommunities.map(
          (item) => item.community?._id
        );
        const filteredCommunities = response?.data?.communities?.filter(
          (community) => userCommunityIds.includes(community._id)
        );
        setCommunities(filteredCommunities);
      }
    };

    const getTradeHistory = async () => {
      const response = await api("get", "users/getTradeHistory");
      if (response?.status) {
        setHistory(response?.data);
      }
    };

    getTradeHistory();

    getCommunities();
  }, []);

  useEffect(() => {
    if (selectedCommunities?.length) {
      let _history = [...history]?.filter((e) =>
        selectedCommunities?.includes(e.community)
      );
      setFilteredHistory(_history);
    } else {
      setFilteredHistory([]);
    }
  }, [selectedCommunities]);

  return (
    <div
      className="row justify-content-center h-100 px-5"
      // style={{ padding: "0px 36px" }}
    >
      <div className="col-lg-12 d-flex flex-column">
        <div
          className="page_title d-flex align-items-center justify-content-between flex-nowrap pe-5 mb-3"
          // style={{ padding: "0px 36px" }}
        >
          <h4 className="fs25 text_GB">Trade history</h4>

          <div className="custom-dropdown d-flex" ref={ref}>
            {/* <InputGroup className="search">
                            <FormControl
                                placeholder="Search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <Button>
                                <FaSearch />
                            </Button>
                        </InputGroup> */}
            <button className="dropdown-toggle" onClick={() => setShow(!show)}>
              <img className="filter-icon" src={searchIcon} />
            </button>
            {show && (
              <div className="dropdown-menu">
                <Accordion defaultActiveKey={""}>
                  <div className="clear-all">
                    <text>Filters</text>
                    <span
                      onClick={() => {
                        setStartDate();
                        setSelectedCommunities([]);
                      }}
                    >
                      Clear All
                    </span>
                  </div>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Communities</Accordion.Header>
                    <Accordion.Body>
                      <Form.Check
                        className="form-check-community mb-2 gap-2"
                        type={"checkbox"}
                        label={`Select All`}
                        checked={
                          selectedCommunities?.length ===
                          communities?.map((c) => {
                            return c._id;
                          })?.length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedCommunities(
                              communities?.map((c) => {
                                return c._id;
                              })
                            );
                          }
                        }}
                      />
                      {communities?.map((com) => (
                        <Form.Check
                          className="form-check-community mb-2 gap-2"
                          type={"checkbox"}
                          label={`${com?.name}`}
                          checked={selectedCommunities?.includes(com?._id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedCommunities((prev) => [
                                ...prev,
                                com._id,
                              ]);
                            } else {
                              let _communities = [
                                ...selectedCommunities,
                              ]?.filter((f) => f !== com._id);
                              setSelectedCommunities(_communities);
                            }
                          }}
                        />
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Trade Date</Accordion.Header>
                    <Accordion.Body className="d-flex align-items-center justify-content-around mt-2 mx-2">
                      <DatePicker
                        className="px-3 py-2 mt-1"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Select Date"
                        maxDate={new Date()}
                      />
                      <span
                        className="ms-1 cursor-pointer"
                        onClick={() => setStartDate()}
                      >
                        <img className="xmark" src={xmark} />
                      </span>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <li></li>
              </div>
            )}
          </div>
        </div>
        {(filteredHistory.length ? filteredHistory : history)?.length > 0 ? (
          <>
            <div
              className="h-100 w-100 d-flex flex-column mt-3 table-responsive"
              // style={{ padding: "0px 24px" }}
            >
              <table className="table table-light centered-table">
                <thead>
                  <tr>
                    {/* <th>Order ID</th> */}
                    <th>Date</th>
                    {/* <th>Type</th> */}
                    <th>Trade</th>
                    {/* <th>Quantity</th> */}
                    <th>Profit</th>
                    {/* <th>Price</th> */}
                    {/* <th>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {(filteredHistory.length > 0 ? filteredHistory : history)
                    ?.filter((e) => {
                      if (startDate) {
                        if (
                          new Date(startDate).toLocaleDateString() ===
                          new Date(e?.timestamp).toLocaleDateString()
                        ) {
                          return e;
                        }
                      } else {
                        return e;
                      }
                    })
                    ?.filter((e, index) => {
                      if (index < page * 10 && index > page * 10 - 11) {
                        return e;
                      }
                    })
                    ?.map((data, i) => (
                      <tr key={i}>
                        {/* <td>
                          <div className="text-center">{data?.orderId}</div>
                        </td> */}
                        <td>
                          <div className="text-center">
                            {new Date(data?.timestamp).toLocaleDateString()}
                          </div>
                        </td>
                        {/* <td>
                          <p className="text-center">{data?.type}</p>
                        </td> */}
                        <td>
                          <p className="text-center">
                            {data?.side + " " + data?.pair}
                          </p>
                        </td>
                        {/* <td>
                          <p
                            className={`${
                              data?.side === "SELL"
                                ? "text-danger"
                                : "text-success"
                            } text-center`}
                          >
                            {data?.quantity}
                            <p>$157.64</p>
                          </p>
                        </td> */}
                        <td>
                          <p
                            className={`${
                              parseFloat(data?.realizedPnl || "0.00") >= 0
                                ? "text-success"
                                : "text-danger"
                            } text-center`}
                          >
                            {parseFloat(data?.realizedPnl || "0.00") || "0.00"}
                          </p>
                        </td>
                        {/* <td>
                          <div className="text-center">{data?.status}</div>
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>

              <div className="d-flex ms-auto">
                {/* <Pagination
                                    setPage={setPage}
                                    page={page}
                                    filtered={filteredHistory}
                                    items={history}
                                /> */}
                <CustomPagination
                  setPage={setPage}
                  page={page}
                  items={history}
                  filtered={filteredHistory}
                />
              </div>
            </div>
            <p className="mt-4">
              See the complete trade history on your exchange.
            </p>
          </>
        ) : (
          <h4 className="m-5">No records found!</h4>
        )}
      </div>
    </div>
  );
}
