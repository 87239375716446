import React, { useEffect, useLayoutEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { storeUser, updateUser } from "../../../redux/users/usersSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";
import DeleteMember from "../../../components/modals/DeleteMember";
import copyIcon from "../../../assets/images/Copy.svg";
import { setShowConnectionSuccessModal } from "../../../redux/loading/loadingSlice";
import { userCommunity } from "../../../redux/community/communitySlice";
import successGif from "../../../assets/images/success.gif";
import errorGif from "../../../assets/images/error.gif";
import paymentOptions from "../../../assets/images/payment-options.svg";
import stripe from "../../../assets/images/stripe.svg";
import threeD from "../../../assets/images/3d-secure.png";
import masterCard from "../../../assets/images/MasterCard.png";
import visa from "../../../assets/images/VisaVerified.png";
import infoIcon from "../../../assets/images/teir-info-icon.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import VipVerificationPopUp from "../VipScreen/VipVerificationPopUp";
import moment from "moment";
import BillingHistory from "./BillingHistory";
import { isBrowser } from "react-device-detect";

const showText = {
  monthly: "month",
  yearly: "year",
};

function Subscribe({ setCommunity }) {
  const [activeTab, setActiveTab] = useState("monthly");
  const [selectedCommunity, setSelectedCommunity] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [paymentSuccessView, setPaymentSuccessView] = useState(false);
  const [paymentFailView, setPaymentFailView] = useState(false);
  const [planSub, setPlanSub] = useState(false);
  const [notShowPaymentDetail, setNotShowPaymentDetail] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [subId, setSubIs] = useState("");

  const [fullcommunity, setfullCommunity] = useState("");
  const [subscription, setSubscription] = useState([]);
  const [checkedIndex, setCheckIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const api = useApi();
  const user = useSelector((state) => state?.user?.user);
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const [tradingCapitalInfoIndex, setTradingCapitalInfoIndex] = useState(false);
  const [vipSubscription, setVipSubscription] = useState(null);
  const [isVipSubscriptionSubscribed, setIsVipSubscriptionSubscribed] =
    useState({ monthly: false, yearly: false });
  const [vipCheckBoxClick, setVipCheckBoxCLick] = useState({
    monthly: false,
    yearly: false,
  });
  const [showVipPopUp, setShowVipPopUp] = useState({
    show: false,
  });
  const [anySubscriptionSubscribe, setAnySubscriptionSubscribe] =
    useState(false);
  const [vipButtonText, setVipButtonText] = useState({
    monthly: "Pay $15",
    yearly: "Pay $180",
  });

  const additionalFee = activeTab === "monthly" ? 15 : 180;

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (!planSub) {
      setCheckIndex(0);
    }
  };

  useEffect(() => {
    if (user?.memberCommunities?.length) {
      for (let index = 0; index < user?.memberCommunities?.length; index++) {
        const element = user?.memberCommunities[index];
        if (element?.cryptoPayAddress && !element?.subscribed) {
          setPaymentInfo({
            id: element?.cryptoPaymentId,
            communityId: element?.community?._id,
            address: element?.cryptoPayAddress,
            subscriptionId: element?.subscription,
            interval: element?.planType,
            price: element?.paidAmount,
            payableAmount: element?.cryptoPayableAmount,
          });

          break;
        }
      }
    }
  }, [user?.memberCommunities]);

  const fetchUserData = async () => {
    const response = await api("get", "users/index");
    if (response?.status) {
      dispatch(storeUser(response?.data));
      localStorage.setItem("token", response?.data?.token);
    }
  };

  const unsubscribeCrypto = async () => {
    let communityId = selectedCommunity?.community?._id;
    dispatch(setLoading(true));
    // localStorage.setItem('customerId', true);
    let response = await api("post", "users/unsubCrypto", {
      communityId,
    });
    if (response?.status) {
      setPlanSub(false);
      setShowModal(false);
      setActiveTab(response?.data?.user?.memberCommunities[0]?.planType);

      await fetchUserData();
      toastify("You have been unsubscribed successfully.", "success");
    } else {
      toastify("Unable to unsubscribe");
    }
    dispatch(setLoading(false));
  };

  const cancelPayment = async () => {
    dispatch(setLoading(true));
    // localStorage.setItem('customerId', 'undefined');
    let response = await api("post", "users/cancelCryptoPayment", {
      communityId: paymentInfo?.communityId,
    });

    if (response?.status) {
      await fetchUserData();
      toastify("Crypto payment cancelled successfully.", "success");
      setPaymentInfo();
    } else {
      toastify("Unable to cancel crypto payment");
    }
    dispatch(setLoading(false));
  };

  const checkStatus = async () => {
    dispatch(setLoading(true));
    let response = await api("post", "users/checkCryptoStatus", {
      paymentId: paymentInfo?.id,
    });

    if (response?.data?.payment_status) {
      let status = response?.data?.payment_status;
      if (status === "failed") {
        setPaymentInfo();
        await cancelPayment();
        toastify("Payment has been failed. Please try again later.");
      } else if (status === "finished") {
        let { interval, price, communityId, subscriptionId } = paymentInfo;
        let subscribe = await api("post", "users/createCryptoSubscription", {
          communityId,
          amount: price,
          interval,
          subscriptionId,
        });
        if (subscribe?.status) {
          setPlanSub(true);
          await fetchUserData();
          setPaymentInfo();
          toastify("Payment confirmed successfully.", "success");
          const responseUser = await api("post", "users/update", {
            ...(user?.firstVisit ? { onboard: "done" } : {}), // Add onboard: "done" only if user.firstVisit is true
            // firstVisit: false,
            onboarding: { ...user.onboarding, payment: true },
            onboardingStep: "",
          });
          if (!responseUser?.data.user?.firstVisit) {
            dispatch(setShowConnectionSuccessModal(true));
            dispatch(setLoading(false));
            setPaymentSuccessView(true);
            setTimeout(() => {
              navigate("/member/tradeActive", { replace: true });
            }, 2000);
          }
        } else {
          toastify(`Unable to subscribe you for ${activeTab} subscription.`);
        }
      } else {
        toastify(`Payment status is ${status}. Please try again later.`);
        dispatch(setLoading(false));
        setPaymentFailView(true);
      }
    }
    dispatch(setLoading(false));
  };

  const createSubscription = async () => {
    try {
      dispatch(setLoading(true));

      const response = await api("post", "users/handlePayments", {
        sessionId: sessionId,
        communityId: id,
      });
      if (response?.status) {
        setPaymentSuccessView(true);
        dispatch(updateUser(response?.data?.user));
        // dispatch(setLoading(false));
        setPlanSub(true);
        dispatch(setShowConnectionSuccessModal(true));
        const responseUser = await api("post", "users/update", {
          ...(user?.firstVisit ? { onboard: "done" } : {}), // Add onboard: "done" only if user.firstVisit is true
          // firstVisit: false,
          onboarding: { ...user.onboarding, payment: true },
        });
        setActiveTab(responseUser?.data?.user?.memberCommunities[0]?.planType);
        dispatch(updateUser(responseUser?.data?.user));
        dispatch(setLoading(false));
        if (responseUser?.data.user?.firstVisit) {
          setTimeout(() => {
            // setPaymentSuccessView(false)
            navigate("/member/tradeActive", { replace: true });
          }, 2000);
        } else {
          setTimeout(() => {
            setPaymentSuccessView(false);
            setNotShowPaymentDetail(false);
            navigate("/member/copyTrade", { replace: true });
          }, 2000);
        }
        toastify("You have been subscribed successfully.", "success");
      } else {
        // toastify(response?.message);
        dispatch(setLoading(false));
        setPaymentFailView(true);
      }
    } catch (err) {
      console.log(err);
      // toastify("Payment Unsuccessful");
    }
  };

  useEffect(() => {
    // dispatch(updateUser(user))
    if (id) {
      createSubscription();
    }
    dispatch(setLoading(false));
    if (user?.memberCommunities[0]?.subscribed) {
      setActiveTab(user?.memberCommunities[0]?.planType);
    }
  }, []);

  useLayoutEffect(() => {
    if (id) {
      setNotShowPaymentDetail(true);
    }
  }, []);

  useEffect(() => {
    const allSubscription =
      user?.memberCommunities?.[0]?.community?.subscription || [];

    const anySubcriped = user?.memberCommunities?.[0]?.subscribed;
    const vipSubscription = allSubscription.filter(
      (sub) => sub.name.endsWith("_Vip") && sub.isAllowed
    )[0];

    console.log("vipSubscription", vipSubscription);

    const isVipSubscriped =
      vipSubscription?._id === user?.memberCommunities?.[0]?.subscription &&
      anySubcriped;

    const planType = user?.memberCommunities?.[0]?.planType;

    setAnySubscriptionSubscribe(anySubcriped);

    if (isVipSubscriped && planType === "monthly") {
      setVipButtonText({
        monthly: "Cancel",
        yearly: "Upgrade",
      });
      setVipCheckBoxCLick({ monthly: true, yearly: false });
      setIsVipSubscriptionSubscribed({
        monthly: true,
        yearly: false,
      });
    } else if (isVipSubscriped && planType === "yearly") {
      setVipButtonText({
        monthly: "Upgrade",
        yearly: "Cancel",
      });
      setVipCheckBoxCLick({ monthly: false, yearly: true });
      setIsVipSubscriptionSubscribed({
        monthly: false,
        yearly: true,
      });
    } else if (user?.memberCommunities?.[0]?.subscribed) {
      setVipButtonText({
        monthly: "Upgrade",
        yearly: "Upgrade",
      });
      setVipCheckBoxCLick({ monthly: false, yearly: false });
      setIsVipSubscriptionSubscribed({
        monthly: false,
        yearly: false,
      });
    } else {
      setVipCheckBoxCLick({
        monthly: "Pay $15",
        yearly: "Pay $180",
      });
      setVipCheckBoxCLick({ monthly: false, yearly: false });
      setIsVipSubscriptionSubscribed({
        monthly: false,
        yearly: false,
      });
      setVipButtonText({
        monthly: "Pay $15",
        yearly: "Pay $180",
      });
    }

    setVipSubscription(vipSubscription);
  }, [user?.memberCommunities]);

  useEffect(() => {
    user?.memberCommunities?.forEach((com) => {
      let subscriptionId = com?.subscription;
      if (com?.subscribed) {
        setSelectedPlan(subscriptionId);
        setfullCommunity(com);

        setPlanSub(true);
      } else {
        setfullCommunity(com);
      }

      return com?.community?.subscription?.find((s, i) => {
        if (s?._id === subscriptionId) {
          if (com.subscribed) {
            setCheckIndex(i);
          } else {
            setCheckIndex(0);
          }
        }
      });
    });

    const allSubscription =
      user?.memberCommunities?.[0]?.community?.subscription || [];

    const vipSubscription = allSubscription.filter(
      (sub) => sub.name.endsWith("_Vip") && sub.isAllowed
    )[0];

    if (user?.memberCommunities) {
      const allSubscriptions = user?.memberCommunities?.flatMap((com) => {
        return (
          com?.community?.subscription?.filter(
            (subscription) =>
              subscription?.isActive ||
              (subscription?._id === com?.subscription &&
                vipSubscription?._id !== com?.subscription &&
                com?.planType == activeTab)
          ) || []
        );
      });
      allSubscriptions.sort((a, b) => a.name.localeCompare(b.name));
      setSubscription(allSubscriptions);

      if (user?.memberCommunities[0]?.subscribed) {
        const ind = allSubscriptions.findIndex(
          (s) => s?._id === user?.memberCommunities[0]?.subscription
        );
        if (ind !== -1) {
          setSelectedPlan(allSubscriptions[ind]);
        } else {
          setSelectedPlan(allSubscriptions[3]);
        }
      } else {
        if (allSubscriptions && allSubscriptions?.length > 0) {
          setSelectedPlan(allSubscriptions[0]);
          if (!user?.memberCommunities[0]?.subscribed) {
            setSubIs(allSubscriptions[0]?._id || 0);
          }
        }
      }
    }
  }, [user?.memberCommunities, activeTab]);

  const handleCheckout = async (com, subscriptionIndex, plan) => {
    setCommunity({
      communityId: com?.community?._id,
      subscription: com?.community?.subscription.find((s) => s._id === subId),
      plan,
    });

    navigate("/member/stripePaymentScreen");
  };

  const yesAction = async () => {
    dispatch(setLoading(true));
    setShowModal(false);
    setShowCancelModal(false);
    const response = await api("post", "users/unsubscribe", {
      sessionId: selectedCommunity?.sessionId,
      communityId: selectedCommunity?.community?._id,
      userId: user._id,
    });

    if (response?.status) {
      // setActiveTab("monthly")
      toastify("You have been unsubscribed successfully!", "success");
      setPlanSub(0);
      setCheckIndex(0);
      dispatch(updateUser(response?.data?.user));
      dispatch(
        userCommunity({
          subscription: subscription[0], // Use subs directly here
          communityId: selectedCommunity?.community?._id, // Use com directly here
          plan: activeTab,
        })
      );
    } else {
      toastify(response?.message);
    }

    dispatch(setLoading(false));
  };

  const noAction = () => {
    setShowModal(false);
  };

  const noActionPayment = () => {
    setShowCancelModal(false);
  };

  const isPlanSubscribed = (com, planType) => {
    const subscribedPlan = com?.subscribedPlan || com?.cryptoSubscription;
    const subscriptionId = com?.subscription;

    const matchingSubscription = com?.community?.subscription?.find(
      (subscription) => subscription._id === subscriptionId
    );

    return (
      com?.subscribed &&
      subscribedPlan &&
      matchingSubscription &&
      (matchingSubscription[`${planType}PlanId`] === subscribedPlan ||
        (com?.cryptoSubscription && com?.planType === planType))
    );
  };

  const handlePaymentFailView = async () => {
    // setPaymentFailView(false)
    // setNotShowPaymentDetail(true)
    // navigate('/member/copyTrade');
    const responseUser = await api("post", "users/update");
    setActiveTab(responseUser?.data?.user?.memberCommunities[0]?.planType);
    setTimeout(() => {
      setPaymentFailView(false);
      setNotShowPaymentDetail(false);
      navigate("/member/copyTrade", { replace: true });
    }, 0);
  };

  function getNextMonthSameDate(date, activeTab) {
    const currentDate = new Date(date);
    if (activeTab === "monthly") {
      currentDate.setMonth(currentDate.getMonth() + 1);
    } else if (activeTab === "yearly") {
      currentDate.setFullYear(currentDate.getFullYear() + 1);
    }
    return currentDate;
  }

  const handleCancelModal = () => {
    setShowCancelModal(true);
  };

  return (
    <React.Fragment>
      {paymentSuccessView ? (
        <div className="connectLoader">
          <div className="text-center">
            <h3 className="fs20 bold li_h30">
              Payment successful, <br /> please hold
            </h3>
            <p className="fs14 mb-50 date-color-light">
              Subscription auto-renews every {activeTab}. <br /> Your next
              billing date is:{" "}
              {moment(getNextMonthSameDate(new Date(), activeTab)).format(
                "DD/MM/YYYY"
              )}
            </p>
            <img src={successGif} alt="loaderGif" height={120} />
          </div>
        </div>
      ) : paymentFailView ? (
        <div className="connectLoader">
          <div className="text-center">
            <h3 className="fs20 li_h30 bold">
              Oops. Something went wrong : &#40;
            </h3>
            <p className="fs14 mb-26">Please try again</p>
            <img src={errorGif} alt="loaderGif" height={248} /> <br />
            <button
              style={{ width: "294px" }}
              className="newCreateButton"
              onClick={() => {
                handlePaymentFailView();
              }}
            >
              Try again
            </button>
          </div>
        </div>
      ) : (
        !notShowPaymentDetail && (
          <>
            {user?.firstVisit && (
              <div className="text-center">
                {user?.memberCommunities[0]?.community?.name ? (
                  <p className="fs16 bold mb-21 text-center">
                    {user?.memberCommunities[0]?.community?.name?.toUpperCase()}
                  </p>
                ) : (
                  <span className="mb-27 text-light">.</span>
                )}
              </div>
            )}
            <div className="pricing p-lg-0">
              <div className="d-flex align-items-center justify-content-start">
                <div className="title w-100">
                  <h3 className="text-xl-start text-md-start text-center font-bold mb-6">
                    Your subscription
                  </h3>

                  {/* Toggle between Monthly/Yearly */}
                  <div className="flex text-center space-x-4 mb-6 mt-4 d-none">
                    <button
                      className={`${
                        activeTab === "monthly"
                          ? "bg-black text-white"
                          : "bg-gray-200"
                      } `}
                      style={{
                        width: "7rem",
                        borderRadius: "12px",
                        borderColor: "transparent",
                      }}
                      onClick={() => handleTabChange("monthly")}
                    >
                      Monthly
                    </button>
                    <button
                      className={`${
                        activeTab === "yearly"
                          ? "bg-black text-white"
                          : "bg-gray-200"
                      } `}
                      style={{
                        width: "7rem",
                        borderRadius: "12px",
                        borderColor: "transparent",
                      }}
                      onClick={() => handleTabChange("yearly")}
                    >
                      Yearly
                    </button>
                  </div>
                </div>
              </div>
              {paymentInfo ? (
                <>
                  <h1 className="fs14 bold mt-2">
                    Please make payment at the following Pay Address ({" "}
                    {paymentInfo?.payableAmount} USDT TRC20)
                  </h1>
                  <div className="url p-0">
                    <div className="position-relative d-flex align-items-center justify-content-end copyBlock mt-2">
                      <input
                        type="text"
                        className="form-control fs15"
                        value={paymentInfo?.address}
                        disabled
                      />
                      <span className="position-absolute me-2">
                        <img
                          className="cursor-pointer me-2"
                          onClick={() => {
                            const payAddress = paymentInfo?.address;

                            navigator.clipboard
                              .writeText(payAddress)
                              .then(() => {
                                toastify(
                                  "Pay Address copied to clipboard!",
                                  "success"
                                );
                              })
                              .catch((error) => {
                                console.error("Clipboard write error:", error);
                                toastify(
                                  "Failed to copy community link to clipboard.",
                                  "error"
                                );
                              });
                          }}
                          alt="copyIcon"
                          src={copyIcon}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="d-flex w-100">
                    <div className="ml-auto">
                      <button className="black-btn" onClick={cancelPayment}>
                        Cancel Payment
                      </button>
                      <button className="black-btn ml-20" onClick={checkStatus}>
                        Verify Payment
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <div className="plan-card">
                      {isBrowser ? (
                        <>
                          <div className="desktop-plan">
                            <div className="row">
                              <div className="col-6">
                                <div className="toggle-month-box text-center">
                                  <button
                                    className={`${
                                      activeTab === "monthly"
                                        ? "bg-black text-white"
                                        : "bg-gray-200"
                                    } `}
                                    onClick={() => handleTabChange("monthly")}
                                  >
                                    Monthly
                                  </button>
                                  <button
                                    className={`${
                                      activeTab === "yearly"
                                        ? "bg-black text-white"
                                        : "bg-gray-200"
                                    } `}
                                    onClick={() => handleTabChange("yearly")}
                                  >
                                    Yearly
                                  </button>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="sub-info-end">
                                  <p className="sub-head-light-text">
                                    Auto-renews {activeTab}. Next billing date:{" "}
                                    {` `}
                                    {planSub
                                      ? moment(
                                          user?.memberCommunities[0]
                                            ?.subscriptionExpiry
                                        ).format("DD/MM/YYYY")
                                      : moment(
                                          getNextMonthSameDate(
                                            new Date(),
                                            activeTab
                                          )
                                        ).format("DD/MM/YYYY")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="ps-3">
                              <div
                                className="row"
                                style={{ paddingLeft: "35px" }}
                              >
                                <div className="col-3">
                                  <div className="">
                                    <p className="sub-head-text">Price</p>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div
                                    className="sub-info-end justify-content-start position-relative"
                                    style={{
                                      width: "fit-content",
                                      left: "-5px",
                                    }}
                                  >
                                    <p className="sub-head-text">
                                      Max. Trading capital
                                    </p>
                                    <div
                                      className="sub-info-btn"
                                      onMouseEnter={() =>
                                        setTradingCapitalInfoIndex(true)
                                      }
                                      onMouseLeave={() =>
                                        setTradingCapitalInfoIndex(false)
                                      }
                                    >
                                      <img src={infoIcon} alt="Info" />
                                    </div>
                                    {tradingCapitalInfoIndex && (
                                      <div
                                        className="tier-info-box p-2 bg-black text-white text-sm rounded-lg shadow-lg"
                                        style={{
                                          width: "15rem",
                                          marginLeft: "15rem",
                                          marginBottom: "1rem",
                                          position: "absolute",
                                          zIndex: 9999,
                                          top: "20px",
                                          right: "20px",
                                        }}
                                      >
                                        <p className="m-2">
                                          The maximum trading capital is the
                                          amount of capital on your connected
                                          trading account that will be used to
                                          trade with.
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="custom-radio-box ps-3">
                              {user?.memberCommunities?.map((com, i) => {
                                return subscription?.map((subs, j) => {
                                  const subscribed = isPlanSubscribed(
                                    com,
                                    activeTab
                                  );

                                  const yearlySubscribed = isPlanSubscribed(
                                    com,
                                    activeTab === "monthly"
                                      ? "yearly"
                                      : "monthly"
                                  );
                                  const isLastItem =
                                    j === com?.community?.subs?.length - 1;

                                  const myPlanSubscribed =
                                    fullcommunity?.subscribedPlan ===
                                      subs?.yearlyPlanId ||
                                    fullcommunity?.subscribedPlan ===
                                      subs?.monthlyPlanId;
                                  const isSamePlanType =
                                    activeTab === fullcommunity.planType;

                                  return (
                                    <div
                                      className={`custom-radio-item ${
                                        isLastItem ? "last-item" : ""
                                      }`}
                                    >
                                      <input
                                        type="radio"
                                        name="plan"
                                        id={j}
                                        value={
                                          selectedPlan &&
                                          selectedPlan[
                                            activeTab === "monthly"
                                              ? "monthlyfee"
                                              : "yearlyfee"
                                          ]
                                            ? selectedPlan[
                                                activeTab === "monthly"
                                                  ? "monthlyfee"
                                                  : "yearlyfee"
                                              ]
                                            : "" // Provide a default value or handle the undefined case
                                        }
                                        // defaultChecked={j == 0}
                                        checked={
                                          com?.subscribed
                                            ? selectedPlan?._id === subs?._id &&
                                              activeTab === com?.planType
                                            : checkedIndex === j
                                        }
                                        onChange={() => {
                                          setSelectedPlan(subs);
                                          setfullCommunity(com);
                                          setCheckIndex(j);
                                          setVipCheckBoxCLick({
                                            monthly: false,
                                            yearly: false,
                                          });
                                          setSubIs(subs._id);
                                          dispatch(
                                            userCommunity({
                                              subscription: subs, // Use subs directly here
                                              communityId: com.community?._id, // Use com directly here
                                              plan: activeTab,
                                            })
                                          );
                                          // setCheckIndexJ(j)
                                        }}
                                        disabled={
                                          subscribed || yearlySubscribed
                                        }
                                      />
                                      <label htmlFor={j}>
                                        <div className="content-box">
                                          <div className="row w-100 align-items-center g-0">
                                            <div className="col-lg-3 col-md-3 col-12">
                                              <div className="d-flex align-items-center justify-content-between w-100">
                                                <p className="main-price w-100">
                                                  {`$${
                                                    Number(
                                                      subs[
                                                        activeTab === "monthly"
                                                          ? "monthlyfee"
                                                          : "yearlyfee"
                                                      ]
                                                    ) + additionalFee
                                                  } / ${showText[activeTab]}`}
                                                </p>
                                                <p className="divider pe-3">
                                                  |
                                                </p>
                                              </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-12">
                                              <p className="sub-price">
                                                {/* {subscription['tradingCapital']} */}
                                                {subs["tradingCapital"]
                                                  ?.toString()
                                                  .toUpperCase() === "UNLIMITED"
                                                  ? "UNLIMITED"
                                                  : `$${(
                                                      subs["tradingCapital"] ||
                                                      0
                                                    ).toLocaleString()}`}
                                              </p>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-12">
                                              <div className="w-100 button-side text-end">
                                                <button
                                                  className={`button medium ms-2 ${
                                                    j != checkedIndex &&
                                                    !fullcommunity?.subscribed
                                                      ? "cursorNotAllowed"
                                                      : ""
                                                  } ${
                                                    planSub &&
                                                    isSamePlanType &&
                                                    myPlanSubscribed
                                                      ? "cancel-btn"
                                                      : "black-btn"
                                                  }`}
                                                  disabled={
                                                    j != checkedIndex &&
                                                    !fullcommunity?.subscribed
                                                  }
                                                  onClick={() => {
                                                    if (
                                                      planSub &&
                                                      isSamePlanType &&
                                                      myPlanSubscribed
                                                    ) {
                                                      setSelectedCommunity(
                                                        fullcommunity
                                                      );
                                                      setShowModal(true);
                                                    } else {
                                                      const subscribed =
                                                        isPlanSubscribed(
                                                          fullcommunity,
                                                          activeTab
                                                        );

                                                      const yearlySubscribed =
                                                        isPlanSubscribed(
                                                          fullcommunity,
                                                          activeTab ===
                                                            "monthly"
                                                            ? "yearly"
                                                            : "monthly"
                                                        );

                                                      subscribed ||
                                                      yearlySubscribed
                                                        ? handleCancelModal()
                                                        : handleCheckout(
                                                            fullcommunity,
                                                            checkedIndex,
                                                            activeTab
                                                          );
                                                    }
                                                  }}
                                                >
                                                  {planSub &&
                                                  isSamePlanType &&
                                                  myPlanSubscribed
                                                    ? "Cancel"
                                                    : subscribed ||
                                                      yearlySubscribed
                                                    ? "Upgrade"
                                                    : `Pay $${
                                                        Number(
                                                          subs[
                                                            activeTab ===
                                                            "monthly"
                                                              ? "monthlyfee"
                                                              : "yearlyfee"
                                                          ]
                                                        ) + additionalFee
                                                      }`}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <span className="check-icon">
                                          <i className="bi bi-check"></i>
                                        </span>
                                      </label>
                                    </div>
                                  );
                                });
                              })}

                              {vipSubscription && (
                                <div className="custom-radio-item">
                                  <input
                                    type="radio"
                                    name="VipPlan"
                                    id="radio-id"
                                    onChange={() => {
                                      if (anySubscriptionSubscribe) return;
                                      setCheckIndex(null);

                                      setVipCheckBoxCLick({
                                        monthly:
                                          activeTab === "monthly"
                                            ? true
                                            : false,
                                        yearly:
                                          activeTab === "yearly" ? true : false,
                                      });
                                    }}
                                    checked={
                                      vipCheckBoxClick[activeTab] ||
                                      isVipSubscriptionSubscribed[activeTab]
                                    }
                                  />
                                  <label htmlFor="radio-id">
                                    <div className="content-box">
                                      <div className="row w-100 align-items-center g-0">
                                        <div className="col-lg-3 col-md-3 col-12">
                                          <div className="d-flex align-items-center justify-content-between w-100">
                                            <p className="main-price w-100">
                                              {`${
                                                activeTab === "monthly"
                                                  ? " $15 /  month"
                                                  : " $180 / year"
                                              }`}
                                            </p>
                                            <p className="divider pe-3">|</p>
                                          </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-12">
                                          <p className="sub-price">{"VIP"}</p>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12">
                                          <div className="w-100 button-side text-end">
                                            <button
                                              className={`button medium ms-2 ${
                                                vipCheckBoxClick[activeTab]
                                                  ? ""
                                                  : "cursorNotAllowed"
                                              } ${
                                                isVipSubscriptionSubscribed[
                                                  activeTab
                                                ]
                                                  ? `cancel-btn`
                                                  : ""
                                              }`}
                                              disabled={
                                                !vipCheckBoxClick[activeTab]
                                              }
                                              onClick={() => {
                                                if (
                                                  isVipSubscriptionSubscribed[
                                                    activeTab
                                                  ]
                                                ) {
                                                  setSelectedCommunity(
                                                    fullcommunity
                                                  );
                                                  setShowModal(true);
                                                } else {
                                                  setShowVipPopUp({
                                                    show: true,
                                                  });
                                                  setCommunity({
                                                    communityId:
                                                      user
                                                        ?.memberCommunities?.[0]
                                                        ?.community?._id,
                                                    subscription:
                                                      vipSubscription,
                                                    plan: activeTab,
                                                  });
                                                }
                                              }}
                                            >
                                              {vipButtonText[activeTab]}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <span className="check-icon">
                                      <i className="bi bi-check"></i>
                                    </span>
                                  </label>
                                </div>
                              )}
                            </div>

                            <div className="row mt-25 align-items-center me-3">
                              <div className="col-12">
                                <div className="payment-method-images justify-content-center">
                                  <img
                                    className="payment-method-img td-secure"
                                    src={threeD}
                                    alt="3D Secure"
                                  />
                                  <img
                                    className="payment-method-img master-cerd"
                                    src={masterCard}
                                    alt="Master Card"
                                  />
                                  <img
                                    className="payment-method-img visa-verified"
                                    src={visa}
                                    alt="visa Verified"
                                  />
                                  <img
                                    className="payment-method-img stripe"
                                    src={stripe}
                                    alt="Stripe"
                                  />
                                </div>
                              </div>
                              {/* <div className="col-6">
                                                                <div className="sub-info-end">
                                                                    <a href="" className="sub-head-light-text">
                                                                        {`Change your credit card >`}
                                                                    </a>
                                                                </div>
                                                            </div> */}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          <div className="toggle-month-box mx-auto text-center">
                            <button
                              className={`${
                                activeTab === "monthly"
                                  ? "bg-black text-white"
                                  : "bg-gray-200"
                              } `}
                              onClick={() => handleTabChange("monthly")}
                            >
                              Monthly
                            </button>
                            <button
                              className={`${
                                activeTab === "yearly"
                                  ? "bg-black text-white"
                                  : "bg-gray-200"
                              } `}
                              onClick={() => handleTabChange("yearly")}
                            >
                              Yearly
                            </button>
                          </div>
                          <div className="row">
                            <div className="col-6">
                              <div className="">
                                <p className="sub-head-text margin">Price</p>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="sub-info-end">
                                <p className="sub-head-text">
                                  Max. Trading capital
                                </p>
                                <div
                                  className="sub-info-btn"
                                  onClick={() =>
                                    setTradingCapitalInfoIndex(true)
                                  }
                                  onMouseLeave={() =>
                                    setTradingCapitalInfoIndex(false)
                                  }
                                >
                                  <img src={infoIcon} alt="Info" />
                                </div>
                              </div>
                            </div>
                          </div>

                          {tradingCapitalInfoIndex && (
                            <div
                              className="tier-info-box p-2 bg-black text-white text-sm rounded-lg shadow-lg"
                              style={{
                                width: "15rem",
                                marginLeft: "15rem",
                                marginBottom: "1rem",
                                position: "absolute",
                                zIndex: 9999,
                                top: "135px",
                                right: "50px",
                              }}
                            >
                              <p className="m-2">
                                The maximum trading capital is the amount of
                                capital on your connected trading account that
                                will be used to trade with.
                              </p>
                            </div>
                          )}
                          <div className="custom-radio-box">
                            {user?.memberCommunities?.map((com, i) => {
                              return subscription?.map((subs, j) => {
                                const subscribed = isPlanSubscribed(
                                  com,
                                  activeTab
                                );

                                const yearlySubscribed = isPlanSubscribed(
                                  com,
                                  activeTab === "monthly" ? "yearly" : "monthly"
                                );

                                const isLastItem =
                                  j === com?.community?.subs?.length - 1;

                                return (
                                  <div
                                    className={`custom-radio-item ${
                                      isLastItem ? "last-item" : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name="plan"
                                      id={j}
                                      value={
                                        selectedPlan &&
                                        selectedPlan[
                                          activeTab === "monthly"
                                            ? "monthlyfee"
                                            : "yearlyfee"
                                        ]
                                          ? selectedPlan[
                                              activeTab === "monthly"
                                                ? "monthlyfee"
                                                : "yearlyfee"
                                            ]
                                          : "" // Provide a default value or handle the undefined case
                                      }
                                      // defaultChecked={j == 0}
                                      checked={
                                        com?.subscribed
                                          ? selectedPlan?._id === subs?._id &&
                                            activeTab === com?.planType
                                          : checkedIndex === j
                                      }
                                      onChange={() => {
                                        setSelectedPlan(subs);
                                        setfullCommunity(com);
                                        setVipCheckBoxCLick({
                                          monthly: false,
                                          yearly: false,
                                        });
                                        setCheckIndex(j);
                                        setSubIs(subs._id);
                                        dispatch(
                                          userCommunity({
                                            subscription: subs, // Use subs directly here
                                            communityId: com.community?._id, // Use com directly here
                                            plan: activeTab,
                                          })
                                        );
                                        // setCheckIndexJ(j)
                                      }}
                                      disabled={subscribed || yearlySubscribed}
                                    />
                                    <label htmlFor={j}>
                                      <div className="content-box">
                                        <div className="row w-100">
                                          <div className="col-7">
                                            <div className=" w-100 d-flex align-items-center justify-content-start">
                                              <p className="main-price w-100">
                                                {`$${
                                                  Number(
                                                    subs[
                                                      activeTab === "monthly"
                                                        ? "monthlyfee"
                                                        : "yearlyfee"
                                                    ]
                                                  ) + additionalFee
                                                } / ${showText[activeTab]}`}
                                              </p>
                                              <p className="divider">|</p>
                                            </div>
                                          </div>
                                          <div className="col-5">
                                            <p className="sub-price text-center w-100">
                                              {/* {subscription['tradingCapital']} */}
                                              {subs["tradingCapital"]
                                                ?.toString()
                                                .toUpperCase() === "UNLIMITED"
                                                ? "UNLIMITED"
                                                : `$${(
                                                    subs["tradingCapital"] || 0
                                                  ).toLocaleString()}`}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <span className="check-icon">
                                        <i className="bi bi-check"></i>
                                      </span>
                                    </label>
                                  </div>
                                );
                              });
                            })}

                            {vipSubscription && (
                              <div className="custom-radio-item">
                                <input
                                  type="radio"
                                  name="VipPlan"
                                  id="radio-id"
                                  onChange={() => {
                                    if (anySubscriptionSubscribe) return;
                                    setCheckIndex(null);

                                    setVipCheckBoxCLick({
                                      monthly:
                                        activeTab === "monthly" ? true : false,
                                      yearly:
                                        activeTab === "yearly" ? true : false,
                                    });
                                  }}
                                  checked={
                                    vipCheckBoxClick[activeTab] ||
                                    isVipSubscriptionSubscribed[activeTab]
                                  }
                                />
                                <label htmlFor="radio-id">
                                  <div className="content-box">
                                    <div className="row w-100 ">
                                      <div className="col-7">
                                        <div className="w-100 d-flex align-items-center justify-content-start">
                                          <p className="main-price w-100">
                                            {`${
                                              activeTab === "monthly"
                                                ? " $15 /  month"
                                                : " $180 / year"
                                            }`}
                                          </p>
                                          <p className="divider">|</p>
                                        </div>
                                      </div>
                                      <div className="col-5">
                                        <p className="sub-price">{"VIP"}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <span className="check-icon">
                                    <i className="bi bi-check"></i>
                                  </span>
                                </label>
                              </div>
                            )}
                          </div>
                          {/* <span className="line-for-web"></span> */}
                          <p className="bottom-light-text">
                            Auto-renews {activeTab}. Next billing date:
                            <span className="mx-1">
                              {planSub
                                ? moment(
                                    user?.memberCommunities[0]
                                      ?.subscriptionExpiry
                                  ).format("DD/MM/YYYY")
                                : moment(
                                    getNextMonthSameDate(new Date(), activeTab)
                                  ).format("DD/MM/YYYY")}
                            </span>
                          </p>
                          <div className="text-center">
                            {showCancelModal && (
                              <DeleteMember
                                noAction={noActionPayment}
                                yesAction={noActionPayment}
                                text="You’re already subscribed"
                                subtext={`Please cancel current subscription and then subscribe to the ${
                                  fullcommunity.planType !== "monthly"
                                    ? "monthly"
                                    : "annual"
                                } plan.`}
                                noneclass={"d-none"}
                              />
                            )}

                            {vipCheckBoxClick[activeTab] ? (
                              <button
                                className={`button medium ms-2 ${
                                  vipCheckBoxClick[activeTab]
                                    ? ""
                                    : "cursorNotAllowed"
                                } ${
                                  isVipSubscriptionSubscribed[activeTab]
                                    ? `cancel-btn`
                                    : ""
                                }`}
                                disabled={!vipCheckBoxClick[activeTab]}
                                onClick={() => {
                                  if (isVipSubscriptionSubscribed[activeTab]) {
                                    setSelectedCommunity(fullcommunity);
                                    setShowModal(true);
                                  } else {
                                    setShowVipPopUp({
                                      show: true,
                                    });
                                    setCommunity({
                                      communityId:
                                        user?.memberCommunities?.[0]?.community
                                          ?._id,
                                      subscription: vipSubscription,
                                      plan: activeTab,
                                    });
                                  }
                                }}
                              >
                                {vipButtonText[activeTab]}
                              </button>
                            ) : (
                              <button
                                className={`button medium ms-2 ${
                                  planSub &&
                                  activeTab === fullcommunity.planType
                                    ? "cancel-btn"
                                    : "black-btn"
                                }`}
                                // disabled={!selectedPlan}
                                onClick={() => {
                                  if (
                                    planSub &&
                                    activeTab === fullcommunity.planType
                                  ) {
                                    setSelectedCommunity(fullcommunity);
                                    setShowModal(true);
                                  } else {
                                    const subscribed = isPlanSubscribed(
                                      fullcommunity,
                                      activeTab
                                    );

                                    const yearlySubscribed = isPlanSubscribed(
                                      fullcommunity,
                                      activeTab === "monthly"
                                        ? "yearly"
                                        : "monthly"
                                    );

                                    subscribed || yearlySubscribed
                                      ? handleCancelModal()
                                      : handleCheckout(
                                          fullcommunity,
                                          checkedIndex,
                                          activeTab
                                        );
                                  }
                                }}
                              >
                                {planSub && activeTab === fullcommunity.planType
                                  ? "Cancel"
                                  : planSub &&
                                    activeTab !== fullcommunity.planType
                                  ? "Upgrade"
                                  : `Pay $${
                                      Number(
                                        selectedPlan[
                                          activeTab === "monthly"
                                            ? "monthlyfee"
                                            : "yearlyfee"
                                        ]
                                      ) + additionalFee || ""
                                    }`}
                              </button>
                            )}
                          </div>
                          <div className="text-center mt-lg-3 mt-md-3">
                            <p className="secure-payment-text">
                              Safe and Secure Payments Checkout
                            </p>
                            <div className="payment-method-images">
                              <img
                                className="payment-method-img td-secure"
                                src={threeD}
                                alt="3D Secure"
                              />
                              <img
                                className="payment-method-img master-cerd"
                                src={masterCard}
                                alt="Master Card"
                              />
                              <img
                                className="payment-method-img visa-verified"
                                src={visa}
                                alt="visa Verified"
                              />
                              <img
                                className="payment-method-img stripe"
                                src={stripe}
                                alt="Stripe"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center mt-3 d-none">
                    <img
                      className="payment-option-img"
                      src={paymentOptions}
                      alt="payment options"
                    />
                    <p className="secure-payment-text">
                      Safe and Secure Payments Checkout
                    </p>
                    <div className="payment-method-images">
                      <img
                        className="payment-method-img td-secure"
                        src={threeD}
                        alt="3D Secure"
                      />
                      <img
                        className="payment-method-img master-cerd"
                        src={masterCard}
                        alt="Master Card"
                      />
                      <img
                        className="payment-method-img visa-verified"
                        src={visa}
                        alt="visa Verified"
                      />
                      <img
                        className="payment-method-img stripe"
                        src={stripe}
                        alt="Stripe"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )
      )}
      <div className="mt-5">
        {!user?.firstVisit && (
          <BillingHistory notShowPaymentDetail={notShowPaymentDetail} />
        )}
      </div>
      {showModal && (
        <DeleteMember
          noAction={noAction}
          yesAction={() => {
            selectedCommunity?.cryptoSubscription
              ? unsubscribeCrypto()
              : yesAction();
          }}
          text="You’re about to cancel your subscription"
          subtext="If you cancel subscription your copy trade will stop working by the end of the subscription period. Please note that there’s no refunds if you choose upgrade your subscription. Do you still want to cancel?"
          no="No"
          yes="Yes"
        />
      )}
      {showVipPopUp?.show && (
        <VipVerificationPopUp
          setShowVipPopUp={setShowVipPopUp}
          callApi={() => {
            navigate("/member/stripePaymentScreen");
          }}
        />
      )}
      <RenderLinks page="copyTradePayment" />
      <div className="sub-del-modal">
        {showCancelModal && (
          <DeleteMember
            noAction={noActionPayment}
            yesAction={noActionPayment}
            text="You’re already subscribed"
            subtext={`Please cancel current subscription to subscribe to your preferred subscription plan.`}
            noneclass={"d-none"}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default Subscribe;
