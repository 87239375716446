import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import AuthLoader from "../components/loader/authLoader";
import LoadingModal from "../components/loader/loadingModal";
import {
  setLoading,
  setResponseLoading,
  setSettingUpService,
  setSigningYouIn,
} from "../redux/loading/loadingSlice";

export const LoginLayout = () => {
  const dispatch = useDispatch();

  const loaders = useSelector((state) => state?.loader);
  const { user } = useSelector((state) => state);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");
    const code = urlParams.get("code");
    const rememberMe = localStorage.getItem("rememberMe");
    // dispatch(setLoading(false));
    if (!(code && state)) {
      if (!loaders?.binanceConnecting) {
        dispatch(setLoading(false));
        dispatch(setResponseLoading(false));
      }
      dispatch(setSigningYouIn(false));
      dispatch(setSettingUpService(false));
    }
    if (!user.user) {
      dispatch(setLoading(false));
    } else if (rememberMe) {
      dispatch(setLoading(true));
    }
    const token = localStorage.getItem("token");
    if (rememberMe && token && !user?.firstVisit) {
      const role = user.user.role;
      if (role === "leader") {
        window.location.href = "/leader/dashboard";
      } else if (role === "member") {
        window.location.href = "/member/dashboard";
      }
    }
  }, []);

  return (
    <>
      {loaders?.isLoading && <LoadingModal />}
      {loaders?.settingUpService && (
        <AuthLoader component="settings" user={user} />
      )}
      {loaders?.signingYouIn && <AuthLoader />}
      <div className="wrapper">
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};
