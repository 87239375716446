import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import {
  setLoading,
  setShowConnectionSuccessModal,
} from "../../../redux/loading/loadingSlice";
import { updateUser, setEditable } from "../../../redux/users/usersSlice";
import { toastify } from "../../../helpers/toast/toastify";
import AddIcon from "../../../assets/images/addIcon.svg";
import RenderLinks from "../../../components/links/RenderLinks";
import infoIcon from "../../../assets/images/teir-info-icon.png";
import AuthLoader from "../../../components/loader/authLoader";
import DeleteMember from "../../../components/modals/DeleteMember";
import { isMobile } from "react-device-detect";
import Select from "react-select";
import TierAction from "../../../assets/images/tierAction.svg";

const options = [
  {
    value: "0",
    label: "No trial for my members",
  },
  {
    value: "7",
    label: "7 days ",
  },
  {
    value: "14",
    label: "14 days",
  },
  {
    value: "30",
    label: "1 month",
  },
];

function Subscription({ setTab, tab }) {
  const api = useApi();
  const user = useSelector((value) => value?.user?.user);
  const inputRef = useRef(null);
  const saveButtonRef = useRef(null); // Ref for the "Save Changes" button
  const loaders = useSelector((state) => state?.loader);

  const [subscription, setSubscription] = useState([
    {
      name: `${user?.leaderCommunity?.name} Subscription Tier 1`,
      monthlyfee: "",
      yearlyfee: "",
      tradingCapital: "",
    },
  ]);
  const [showModal, setShowModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const editable = useSelector((value) => value?.user?.editable);
  const dispatch = useDispatch();
  const numberRegex = new RegExp(`^[0-9]*$`);
  const [tagData, setTagData] = useState([]);
  const [editableIndex, setEditableIndex] = useState(null);
  const [tradingCapitalInfoIndex, setTradingCapitalInfoIndex] = useState(false);
  const [selectedTrailOption, setSelectedTrailOption] = useState(null);
  const [noTrailSelect, setNoTrailSelect] = useState(false);

  const handleAddTier = (index) => {
    setTimeout(() => {
      if (saveButtonRef.current) {
        saveButtonRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });

        window.scrollBy(0, 100 * subscription.length);
      }
    }, 0);

    setEditableIndex(index);
    dispatch(setEditable(false));
    setDeleteIndex(index);
    setSubscription((prevSubscription) => [
      ...prevSubscription,
      {
        name: `${user?.leaderCommunity?.name} Subscription Tier ${
          prevSubscription.length + 1
        }`,
        monthlyfee: "",
        yearlyfee: "",
        tradingCapital: "",
      },
    ]);
  };

  const handleDeleteTier = (index) => {
    setSubscription((prevSubscription) => {
      const newSubscription = [...prevSubscription];
      newSubscription.splice(index, 1);
      setSubscription(newSubscription);
      dispatch(setEditable(false));

      return newSubscription;
    });
  };

  useEffect(() => {
    if (user?.leaderCommunity) {
      if (user?.leaderCommunity?.subscription?.length > 0) {
        setSubscription(user?.leaderCommunity?.subscription);
        const trailOption =
          user?.leaderCommunity?.subscription[
            user?.leaderCommunity?.subscription?.length - 1
          ];

        if (trailOption) {
          const selectOption = options.find(
            (option) => option.value === trailOption?.trialPeriodDays
          );

          setSelectedTrailOption(selectOption);
        }
      }
      setTagData(user?.leaderCommunity?.pair || []);
    }
  }, [user]);

  useEffect(() => {
    if (!user?.firstVisit && user?.leaderCommunity?.subscription?.length > 0) {
      const activeSubscription = user.leaderCommunity.subscription.filter(
        (tier) => tier.isActive
      );
      activeSubscription.sort((a, b) => a.name.localeCompare(b.name));

      setSubscription(activeSubscription);
    }
  }, [user]);

  const yesAction = async (index) => {
    dispatch(setLoading(true));
    setShowModal(false);
    if (!subscription[index]?._id) {
      handleDeleteTier(index);
      dispatch(setLoading(false));
      toastify("Subscription deleted successfully!", "success");
      return;
    }
    const response = await api("post", "users/deleteSubscription", {
      subscriptionId: subscription[index]?._id,
    });

    if (response?.status) {
      const response = await api("get", "users/index");
      if (response?.status) {
        dispatch(updateUser(response?.data?.user));
      }
      toastify("Subscription deleted successfully!", "success");
    }

    dispatch(setLoading(false));
  };

  const noAction = () => {
    setShowModal(false);
  };

  const handleInputChange = (value, key, tierIndex) => {
    setSubscription((prevSubscription) => {
      const newValues = [...prevSubscription];

      // Ensure the tier object exists
      if (!newValues[tierIndex]) {
        newValues[tierIndex] = {
          name: "",
          monthlyfee: "",
          yearlyfee: "",
          tradingCapital: "",
        };
      } else if (key === "pair") {
        newValues[tierIndex] = {
          ...newValues[tierIndex],
          pair: [...newValues[tierIndex].pair, value],
        };
      } else {
        newValues[tierIndex] = {
          ...newValues[tierIndex],
          [key]: value,
        };
      }
      return newValues;
    });
  };

  function checkTradingCapital(data) {
    for (const item of data) {
      if (item.monthlyfee === undefined || item.monthlyfee === "") {
        dispatch(setEditable(false));
        return {
          status: false,
          message: "Monthly fee must not be null or empty",
        };
      }

      if (item.yearlyfee === undefined || item.yearlyfee === "") {
        dispatch(setEditable(false));
        return {
          status: false,
          message: "Yearly fee must not be null or empty",
        };
      }

      if (
        item.tradingCapital === undefined ||
        parseFloat(item.tradingCapital) <= 0
      ) {
        dispatch(setEditable(false));
        return {
          status: false,
          message: "Max Trading Capital can't be zero or empty",
        };
      }
      const isNumber =
        !isNaN(item.tradingCapital) && parseFloat(item.tradingCapital) > 0;
      const isUnlimited = item.tradingCapital.toUpperCase() === "UNLIMITED";

      if (
        !isNumber &&
        !isUnlimited &&
        item?.tradingCapital != "" &&
        item?.tradingCapital != undefined
      ) {
        dispatch(setEditable(false));
        toastify("Please enter a valid number or 'UNLIMITED'");
        return {
          status: false,
          message: "Invalid trading capital value",
        };
      }
    }
    return {
      status: true,
      message: "",
    };
  }

  const handleSelectChange = (option) => {
    // dispatch(setEditable(false));
    setNoTrailSelect(false);
    setSelectedTrailOption(option); // Update selected option
  };
  const submit = async () => {
    if (selectedTrailOption == null) {
      toastify("Please select free trial");
      dispatch(setLoading(false));
      setNoTrailSelect(true);
      return;
    }
    setNoTrailSelect(false);
    dispatch(setEditable(true));
    setEditableIndex(null);

    if (!subscription.length > 0) {
      toastify("Please fill atleast 1 tier.", "error");
      return;
    }

    const formattedSubscription = subscription.map((tier) => ({
      ...tier,
      monthlyfee: tier.monthlyfee
        ? parseInt(tier.monthlyfee, 10).toString()
        : tier.monthlyfee,
      yearlyfee: tier.yearlyfee
        ? parseInt(tier.yearlyfee, 10).toString()
        : tier.yearlyfee,
      tradingCapital:
        tier.tradingCapital && !isNaN(tier.tradingCapital)
          ? parseInt(tier.tradingCapital, 10).toString()
          : tier.tradingCapital,
    }));

    const filteredSubscription = formattedSubscription.filter(
      (tier) => tier.name
    );
    const isValid = checkTradingCapital(filteredSubscription);

    if (!isValid.status) {
      toastify(`${isValid.message}`, "error");
      return;
    }

    dispatch(setLoading(true));
    const updatedSubscription = {
      subscription: filteredSubscription.map((sub) => ({
        ...sub,
        tradingCapital:
          sub.tradingCapital === "" ? "UNLIMITED" : sub.tradingCapital,
        freeTrial: selectedTrailOption ? selectedTrailOption?.value : 0,
      })),
      pair: tagData, // Assuming tagData is an array
    };

    try {
      const response = await api("post", "users/submitSubscription", {
        updatedSubscription,
      });
      if (response?.status) {
        dispatch(setLoading(false));
        dispatch(setShowConnectionSuccessModal(true));
        // Update user data and handle other logic
        const response2 = await api("get", "users/index");
        if (response2.status) {
          const { onboarding, firstVisit } = response2?.data?.user;
          if (onboarding?.subscribed && firstVisit) {
            const isValid = checkTradingCapital(filteredSubscription);

            if (!isValid.status) {
              toastify(`${isValid.message}`, "error");
              return;
            }

            const response3 = await api("post", "users/update", {
              onboardingStep: "connection",
            });
            if (response3.status && response?.status && response2.status) {
              setTab("connection");
              dispatch(updateUser(response3?.data?.user));
              dispatch(setShowConnectionSuccessModal(false));
              toastify("Subscription added successfully.", "success");
            }
          } else if (!firstVisit) {
            toastify("Subscription updated successfully.", "success");
            // dispatch(setLoading(false))
            setTimeout(() => {
              dispatch(setShowConnectionSuccessModal(false));
              dispatch(updateUser(response2?.data?.user));
            }, 2000);
          }
        }
      } else {
        toastify(response?.message);
      }
    } catch (error) {
      toastify("An error occurred while submitting subscription.", "error");
    }

    // dispatch(setLoading(false));
  };

  const handleEdit = (index) => {
    setEditableIndex(index);
    dispatch(setEditable(false));
    closeDropdown(index);
    // Use setTimeout to ensure that the input is focused before selecting text
    // Create a temporary input element to force the keyboard to open on iOS
    // let userAgent = navigator?.userAgent;
    // let tempInput = null;
    // if (!userAgent?.includes("Firefox")) {
    //   tempInput = document.createElement("input");
    //   document.body.appendChild(tempInput);
    //   tempInput.className = "hidden-input";
    //   tempInput.focus();
    // }

    setTimeout(() => {
      if (inputRef?.current) {
        inputRef?.current?.focus();
        inputRef?.current?.focus();
      }
    }, 0);
  };

  const activeSubscriptions = user?.leaderCommunity?.subscription?.filter(
    (sub) => sub?.isActive
  )?.length;

  const [isHovered, setIsHovered] = useState(false);

  const [openDropdowns, setOpenDropdowns] = useState({});
  const dropdownRefs = useRef([]); // Array to hold refs for all dropdowns

  const toggleDropdown = (index) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle the specific dropdown
    }));
  };

  const closeDropdown = (index) => {
    setOpenDropdowns((prev) => ({
      ...prev,
      [index]: false, // Close the specific dropdown
    }));
  };

  const handleClickOutside = (e) => {
    // Check if the click was outside any of the dropdowns
    if (
      dropdownRefs.current &&
      !dropdownRefs.current.some((ref) => ref && ref.contains(e.target))
    ) {
      // Close all dropdowns if clicked outside
      setOpenDropdowns({});
    }
  };

  // Add event listener for clicks outside on mount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    // Cleanup the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      {loaders?.showConnectionSuccessModal ? (
        <AuthLoader
          component="connectionsuccess"
          closeHeadline="Subscription Created!"
        />
      ) : (
        <div className="subscription subscriptionJs">
          {!user?.firstVisit && (
            <div className="title">
              <h3>Subscriptions</h3>

              <p>
                Please create minimum 1 subscription tier for your service to
                work.
              </p>
            </div>
          )}
          {user?.firstVisit && (
            <div className="title">
              <h3 className="text-center text-lg-start text-md-start subscription-heading-margin">
                Create your subscription
              </h3>

              <p className="text-center text-lg-start text-md-start">
                Please define your subscription.
              </p>
            </div>
          )}
          <div className="tier mx-691">
            <div className="freeTrailBox">
              <p
                className="cl-tier-heading d-flex align-items-center gap-2 TierPositionSetup"
                style={{ width: "fit-content" }}
              >
                Free trial for new subscribers
                <div
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  style={{
                    width: "21px",
                    height: "21px",
                    top: "-2px",
                    position: "relative",
                    cursor: "pointer",
                  }}
                  className=""
                >
                  <img
                    style={{
                      width: "21px",
                      height: "21px",
                      position: "relative",
                    }}
                    className="info-tier-icon"
                    src={infoIcon}
                    alt="Info"
                  />
                </div>
                {isHovered && (
                  <div
                    className="cl-tier-modal p-2 bg-black text-white text-sm rounded-lg shadow-lg"
                    style={{
                      width: "15rem",
                      zIndex: 9999,
                      position: "absolute", // Position it near the image
                      top: "25px", // Adjust this value to place it below the image
                      left: "100%", // Adjust this to align it as desired
                      fontFamily: "Graphik Regular",
                    }}
                  >
                    <p className="m-2">
                      <span>Free trial</span> <br />
                      We recommend you offer some kind of free trial. The
                      subscribers won’t be charged if subscription is cancelled
                      before end of trial.
                    </p>
                  </div>
                )}
              </p>
              <div className="">
                <div className="settingDropdown freeTrailDropdown">
                  <div
                    className="select"
                    style={{ border: noTrailSelect ? "1px solid #ff0202" : "" }}
                  >
                    <Select
                      value={selectedTrailOption}
                      options={options}
                      onChange={handleSelectChange}
                      classNamePrefix="my-select"
                      isSearchable={false}
                      placeholder="Select an option"
                      styles={{
                        singleValue: (base) => ({
                          ...base,
                          display: "flex",
                          alignItems: "center",
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          transform: state.selectProps.menuIsOpen
                            ? "rotate(180deg)"
                            : null,
                          transition: "transform 0.3s ease",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused
                            ? "#f7f7f7"
                            : "white", // Hover background color
                          fontWeight: state.isFocused ? "bold" : "normal", // Hover font weight
                          color: "black", // Text color
                          cursor: "pointer", // Pointer cursor
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
            {subscription.map((tier, index) => {
              return (
                <div className="TierPositionSetup tier-box-append">
                  <div className="row">
                    <div className="col-6">
                      <p className="cl-tier-heading d-flex align-items-center gap-2">
                        Tier {` ${index + 1}`}
                        <div
                          onMouseEnter={
                            !isMobile
                              ? () => setTradingCapitalInfoIndex(index)
                              : undefined
                          }
                          onClick={
                            isMobile
                              ? () => setTradingCapitalInfoIndex(index)
                              : undefined
                          }
                          onMouseLeave={() => setTradingCapitalInfoIndex(null)}
                          style={{
                            width: "21px",
                            height: "21px",
                            top: "-2px",
                            position: "relative",
                            cursor: "pointer",
                          }}
                          className=""
                        >
                          <img
                            style={{
                              width: "21px",
                              height: "21px",
                              top: "-0px",
                              position: "relative",
                            }}
                            className="info-tier-icon"
                            src={infoIcon}
                            alt="Info"
                          />
                        </div>
                      </p>
                    </div>
                    <div className="col-6">
                      <div className="text-end">
                        <div
                          ref={(el) => (dropdownRefs.current[index] = el)}
                          className="dropdown tierActionDropdown"
                        >
                          <button
                            className="dropdown-toggle"
                            role="button"
                            onClick={() => toggleDropdown(index)}
                            aria-expanded={
                              openDropdowns[index] ? "true" : "false"
                            }
                          >
                            <img src={TierAction} alt="actions" />
                          </button>

                          {openDropdowns[index] && (
                            <ul className="dropdown-menu show">
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleEdit(index)}
                                  href=""
                                >
                                  Edit
                                </button>
                              </li>
                              <li>
                                {subscription.length !== 1 &&
                                activeSubscriptions !== 1 ? (
                                  <button
                                    className="dropdown-item"
                                    onClick={() => {
                                      setShowModal(true);
                                      setDeleteIndex(index);
                                      closeDropdown(index);
                                    }}
                                  >
                                    Delete
                                  </button>
                                ) : (
                                  ""
                                )}
                                {activeSubscriptions === 1 &&
                                  subscription.length >= 2 &&
                                  index > 0 && (
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        setShowModal(true);
                                        setDeleteIndex(index);
                                        closeDropdown(index);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  )}
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" key={index}>
                    <div className="col-lg-4 col-md-4 col-6">
                      <div className="">
                        <div>
                          <input
                            ref={editableIndex === index ? inputRef : null}
                            type="text"
                            autoComplete="new-password"
                            placeholder="Monthly price"
                            className="form-control placeholder-black"
                            style={{
                              color: "inherit",
                              backgroundColor: "white",
                            }}
                            disabled={
                              user?.firstVisit ? false : editableIndex !== index
                            }
                            id={`floatingInput_${index}`}
                            value={
                              tier?.monthlyfee !== undefined
                                ? tier?.monthlyfee === ""
                                  ? ""
                                  : `$${tier?.monthlyfee}`
                                : ""
                            }
                            onChange={(val) => {
                              if (val.target) {
                                const inputWithoutDollar =
                                  val.target.value.replace(/^\$/, "");
                                const isValidNumber =
                                  numberRegex.test(inputWithoutDollar) ||
                                  inputWithoutDollar === "";

                                if (
                                  isValidNumber &&
                                  inputWithoutDollar.length <= 5
                                ) {
                                  handleInputChange(
                                    inputWithoutDollar,
                                    "monthlyfee",
                                    index
                                  );
                                }
                              }
                            }}
                          />
                        </div>

                        <span className="cl-tier-label">
                          <span className="ms-1">
                            {tier?.monthlyfee == ""
                              ? ""
                              : `$${
                                  tier?.monthlyfee &&
                                  parseInt(tier?.monthlyfee) + 15
                                }`}
                          </span>
                          /month
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6">
                      <div className="">
                        <div>
                          <input
                            type="text"
                            placeholder="Yearly price"
                            style={{
                              color: "inherit",
                              backgroundColor: "white",
                            }}
                            className="form-control placeholder-black"
                            autoComplete="new-password"
                            disabled={
                              user?.firstVisit ? false : editableIndex !== index
                            }
                            id={`floatingInput_${index + 1}`}
                            value={
                              tier?.yearlyfee !== undefined
                                ? tier?.yearlyfee === ""
                                  ? ""
                                  : `$${tier?.yearlyfee}`
                                : ""
                            }
                            onChange={(val) => {
                              const inputWithoutDollar =
                                val.target.value.replace(/^\$/, "");
                              const isValidNumber =
                                numberRegex.test(inputWithoutDollar) ||
                                inputWithoutDollar === "";

                              if (
                                isValidNumber &&
                                inputWithoutDollar.length <= 5
                              ) {
                                handleInputChange(
                                  inputWithoutDollar,
                                  "yearlyfee",
                                  index
                                );
                              }
                            }}
                          />
                        </div>

                        <span className="cl-tier-label">
                          <span className="ms-1">
                            {tier?.yearlyfee == ""
                              ? ""
                              : `$${
                                  tier?.yearlyfee &&
                                  parseInt(tier?.yearlyfee) + 180
                                }`}
                          </span>
                          /year
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-6">
                      <div className="maxAdjust">
                        <div>
                          <input
                            type="text"
                            placeholder="Max. Trading capital"
                            style={{
                              color: "inherit",
                              backgroundColor: "white",
                            }}
                            className="form-control mb-0-set"
                            disabled={
                              user?.firstVisit ? false : editableIndex !== index
                            }
                            value={
                              tier?.tradingCapital !== undefined
                                ? tier?.tradingCapital === ""
                                  ? ""
                                  : tier?.tradingCapital.toUpperCase() ===
                                    "UNLIMITED"
                                  ? tier?.tradingCapital
                                  : !isNaN(tier?.tradingCapital)
                                  ? `$${tier?.tradingCapital}`
                                  : tier?.tradingCapital
                                : ""
                            }
                            maxLength={9}
                            onBlur={(val) => {
                              const inputValue = val.target.value.trim();
                              const inputWithoutDollar = inputValue.replace(
                                /^\$/,
                                ""
                              );

                              const isUnlimited =
                                inputWithoutDollar.toUpperCase() == "UNLIMITED";
                              const isNumber =
                                !isNaN(inputWithoutDollar) &&
                                inputWithoutDollar != "";

                              if (isUnlimited) {
                                handleInputChange(
                                  "UNLIMITED",
                                  "tradingCapital",
                                  index
                                );
                              } else if (isNumber) {
                                const roundedValue = Math.round(
                                  parseFloat(inputWithoutDollar)
                                );
                                handleInputChange(
                                  `${roundedValue}`,
                                  "tradingCapital",
                                  index
                                );
                              } else if (inputWithoutDollar != "") {
                                toastify(
                                  "Please enter a valid number or 'Unlimited'"
                                );
                              } else if (inputWithoutDollar == "") {
                                handleInputChange("", "tradingCapital", index);
                              }
                            }}
                            onChange={(val) => {
                              const inputValue = val.target.value;
                              const inputWithoutDollar = inputValue.replace(
                                /^\$/,
                                ""
                              );
                              handleInputChange(
                                inputWithoutDollar,
                                "tradingCapital",
                                index
                              );
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {tradingCapitalInfoIndex === index && (
                    <div
                      className="cl-tier-modal p-2 bg-black text-white text-sm rounded-lg shadow-lg innerTier"
                      style={{
                        width: "15rem",
                        zIndex: 9999,
                      }}
                    >
                      <p className="m-2">
                        <span>Subscription Tier</span> <br />
                        Min. 1 subscription tier is needed for your copy trading
                        to work. We’ll add $15 to your subscription fee. Maximum
                        trading capital is the amount of capital subscribers
                        will be copy trading with. This feature makes it
                        possible for you to charge more for subscribers with
                        more capital. Leave it blank for unlimited trading
                        capital.
                      </p>
                    </div>
                  )}

                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <hr
                      style={{
                        width: "100%",
                        border: "1px solid #c1c1c1",
                        margin: "27px 0 17px 0",
                      }}
                    />
                  </div>
                </div>
              );
            })}

            <div className="d-lg-flex d-md-flex aling-items-center justify-content-between">
              <div className="text-start tier-bottom-btn-box">
                {subscription.length < 5 && (
                  <div
                    className="addTier"
                    onClick={() => handleAddTier(subscription.length)}
                  >
                    <img src={AddIcon} className="addIcon" />
                    <p>Add extra tier</p>
                  </div>
                )}
              </div>
              <div>
                {user?.firstVisit && (
                  <div
                    className="d-flex justify-content-end"
                    style={{
                      marginTop: "0",
                    }}
                  >
                    <button
                      ref={saveButtonRef}
                      className="btn cyan-btn fs13 tier-save-btn next"
                      style={{ fontSize: "12px", margin: "0 auto" }}
                      onClick={submit}
                    >
                      {"Done"}
                    </button>
                  </div>
                )}

                {!user?.firstVisit && !editable && (
                  <div className="d-flex justify-content-end">
                    <button
                      ref={saveButtonRef}
                      className="btn cyan-btn f13 tier-save-btn"
                      style={{
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                        maxWidth: "90%",
                        margin: "0 auto",
                      }}
                      onClick={submit}
                    >
                      Save Changes
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {showModal && (
            <DeleteMember
              noAction={noAction}
              yesAction={() => yesAction(deleteIndex)} // Pass the delete index here
              text="Delete subscription"
              subtext="Are you sure you want to delete this pricing?"
            />
          )}
          <RenderLinks page="copyTradeSubscription" />
        </div>
      )}
    </>
  );
}

export default Subscription;
