import React, { useEffect, useRef, useState } from "react";
import Stripe from "../../../assets/images/stripe.png";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../../redux/users/usersSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";
import DeleteMember from "../../../components/modals/DeleteMember";
import UpdateAndVerifyAccountOTP from "../../sharedPages/accountSettings/UpdateAndVerifyAccountOTP";

function Payment({ setTab }) {
  const [isEditing, setIsEditing] = useState(false);
  const [usdtWalletAddress, setUsdtWalletAddress] = useState("");
  const [checkedStripeAccount, setCheckedStripeAccount] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showCryptoModal, setShowCryptoModal] = useState(false);
  const [showOtpPopUp, setShowOtpPopUp] = useState({
    show: false,
    buttonClick: "",
  });

  const [show, setShow] = useState(null);
  const [showPaymentScreen, setShowPaymentScreen] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const api = useApi();
  const user = useSelector((state) => state?.user?.user);
  const { id } = useParams();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const noAction = () => {
    setShowModal(false);
  };

  const noCryptoAction = () => {
    setShowCryptoModal(false);
  };

  const handleSave = async () => {
    if (!usdtWalletAddress) {
      toastify("Please add wallet adddress!", "error");
      return;
    }
    dispatch(setLoading(true));
    const response = await api("post", "users/update", {
      usdtWalletAddress,
      getPaidInUSD: false,
      getPaidInCrypto: true,
    });
    if (response?.status) {
      dispatch(setLoading(false));

      dispatch(updateUser(response?.data?.user));
      setIsEditing(false);
      toastify("Wallet address added successfully!", "success");
      if (user?.firstVisit) {
        setTab("onboarding");
      }
    } else {
      dispatch(setLoading(false));
      toastify(response?.message);
    }
  };

  const yesCryptoAction = () => {
    handleEdit();
    setShowCryptoModal(false);
  };

  const handleEdit = () => {
    setIsEditing(true);

    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.className = "hidden-input";
    tempInput.focus();

    setTimeout(() => {
      tempInput?.remove();
      if (inputRef.current) {
        inputRef?.current?.focus();
      }
    }, 0);
  };

  const handleInputChange = (e) => {
    setUsdtWalletAddress(e.target.value);
  };

  const removeAccountIdFromUrl = () => {
    const newPath = location.pathname.replace(/\/acct_[^/]+$/, "");
    window.history.replaceState(null, "", newPath);
  };

  const handleUpdate = () => {
    setCheckedStripeAccount(true);

    setTimeout(() => {
      window.location.assign(user?.stripeCredentials?.stripeAccountLink);
    }, 0);
  };
  const stripeCheckAccount = async () => {
    dispatch(setLoading(true));
    let userAgent = navigator?.userAgent;
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      dispatch(setLoading(false));
    }
    try {
      const response = await api("get", "users/checkAccount");
      dispatch(setLoading(false));

      if (response.message == "Connect Onboarding is not completed!") {
        dispatch(updateUser(response?.data?.user));
        setShowPaymentScreen("Connect Onboarding is not completed!");
        navigate("/leader/copyTrade");
        // dispatch(setLoading(false))
      } else if (response.message == "Details added" && !user?.firstVisit) {
        dispatch(updateUser(response?.data?.user));
        setShowPaymentScreen("Details added");
      } else if (
        response.message ==
        "Can't create payments! You missed required information on stripe account."
      ) {
        toastify(
          "Can't create payments! You missed required information on stripe account."
        );
        setShowPaymentScreen(
          "Can't create payments! You missed required information on stripe account."
        );
      } else if (user?.firstVisit) {
        // localStorage.removeItem("showPaymentPage")
        dispatch(updateUser(response?.data?.user));
        setTab("onboarding");
      }
    } catch {
      toastify("An error occurred. Please try again.", "error");
    }
  };

  useEffect(() => {
    stripeCheckAccount();
  }, []);

  const checkStripeAccount = async () => {
    dispatch(setLoading(true));
    let userAgent = navigator?.userAgent;
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      dispatch(setLoading(false));
    }
    try {
      const response = await api("get", "users/checkAccount", {
        sendEmail: true,
      });

      if (response.status) {
        dispatch(updateUser(response?.data?.user));
        if (response?.data?.isValid) {
          navigate("/leader/copyTrade");
        } else {
          if (response?.data?.user) {
            toastify(response?.message, "success");
            const UpdateResponse = await api("post", "users/update", {
              getPaidInUSD: true,
              getPaidInCrypto: false,
              usdtWalletAddress: "",
            });

            if (location.pathname.includes("/acct_")) {
              removeAccountIdFromUrl();
            }
            setIsEditing(false);
            setUsdtWalletAddress("");
            if (UpdateResponse.status) {
              dispatch(updateUser(UpdateResponse?.data?.user));
            }
          }
          if (user?.firstVisit) {
            setTab("onboarding");
          }
        }
      } else {
        toastify(response?.message);
      }
    } catch (error) {
      console.error("Error:", error);
      toastify("An error occurred. Please try again.", "error");
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (id && !checkedStripeAccount) {
      checkStripeAccount();
    }
  }, [id, checkedStripeAccount]);

  useEffect(() => {
    if (user?.usdtWalletAddress) {
      setUsdtWalletAddress(user.usdtWalletAddress || "");
    }
  }, [user]);

  const yesAction = async () => {
    let userAgent = navigator?.userAgent;
    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(true));
    }

    setTimeout(() => {
      handleUpdate();
    }, 1000);
    setShowModal(false);
  };

  const callApi = async (buttonClick) => {
    if (showOtpPopUp?.buttonClick === "Cripto" || buttonClick === "Cripto") {
      if (!user?.getPaidInUSD && !user?.getPaidInCrypto) {
        handleEdit();
      } else if (user?.getPaidInCrypto) {
        setShow(true);
      } else {
        setShowCryptoModal(true);
      }
    } else {
      if (!user?.getPaidInUSD && !user?.getPaidInCrypto) {
        handleUpdate();
      } else if (user?.getPaidInUSD) {
        setShow(true);
      } else {
        setShowModal(true);
      }
    }
  };

  return (
    <>
      <React.Fragment>
        {showPaymentScreen == "" ? (
          ""
        ) : (
          <div className="payment pb-sm-50px">
            <div className="title">
              <h3>How would you like to get paid?</h3>
              <p>
                All payments from your members are processed safely by
                Stripe.com (all major creditcards are accepted) and soon we'll
                accept crypto payments too.
              </p>
              <p className="mt-3">
                You can have the subscription fees paid to your account
                immediately via stripe.com or have it paid in crypto once a
                month (USDT, on TRX network). All up to you.
              </p>
            </div>
            <div className="mx-536">
              <div
                className={`paymentCard ${
                  user?.getPaidInUSD
                    ? "payment-complete py-comp-only-red-border"
                    : ""
                }`}
              >
                <div className="account">
                  <h4>Get paid in USD</h4>
                  <img src={Stripe} alt="Stripe" />
                </div>
                <button
                  className={`black-btn promoteBtn ${
                    user?.getPaidInUSD
                      ? `cursorNotAllowed ${
                          user?.firstVisit ? "" : "button-on-red"
                        }`
                      : ""
                  }
                        }`}
                  onClick={() => {
                    if (user.firstVisit) {
                      callApi("Stripe");
                    } else {
                      if (user?.getPaidInUSD) {
                        setShow(true);
                      } else {
                        setShowOtpPopUp({
                          show: true,
                          buttonClick: "Stripe",
                        });
                      }
                    }
                  }}
                >
                  {user?.getPaidInUSD ? "Cancel" : "Set up"}
                </button>
              </div>
              <div
                className={`paymentCard2 ${
                  user?.getPaidInCrypto ? "py-comp-only-red-border" : ""
                }`}
              >
                <div className="saveEdit">
                  <div className="inputDiv">
                    <div className="account">
                      <h4>Get paid in crypto</h4>
                    </div>
                    <div className="form-floating">
                      <input
                        ref={inputRef}
                        className="form-control"
                        id="walletAddress"
                        type="text"
                        placeholder="Add USDT address (TRC20)"
                        value={usdtWalletAddress}
                        onChange={handleInputChange}
                        disabled={!isEditing}
                        style={{
                          borderBottom: isEditing
                            ? "1px solid #000"
                            : "1px solid #000",
                          backgroundColor: isEditing ? "#fff" : "transparent",
                        }}
                      />
                      <label for="walletAddress fs13">
                        Add USDT address (TRC20)
                      </label>
                    </div>
                  </div>
                  {isEditing ? (
                    <button
                      className={`black-btn promoteBtn`}
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className={`black-btn promoteBtn  ${
                        user?.getPaidInCrypto
                          ? "cursorNotAllowed button-on-red"
                          : ""
                      }`}
                      onClick={() => {
                        if (user.firstVisit) {
                          callApi("Cripto");
                        } else {
                          if (user?.getPaidInCrypto) {
                            setShow(true);
                          } else {
                            setShowOtpPopUp({
                              show: true,
                              buttonClick: "Cripto",
                            });
                          }
                        }
                      }}
                    >
                      {user?.getPaidInCrypto ? "Cancel" : "Edit"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <RenderLinks page="copyTradePayment" />
      </React.Fragment>
      {showModal && (
        <DeleteMember
          noAction={noAction}
          yesAction={yesAction}
          text="Setting up USD payouts"
          subtext="This will delete your crypto payout setup and you will be paid in USD going forward."
        />
      )}
      {showCryptoModal && (
        <DeleteMember
          noAction={noCryptoAction}
          yesAction={yesCryptoAction}
          text="Setting up crypto payouts"
          subtext="This will delete your USD payout setup and you will be paid in crypto going forward."
        />
      )}
      {show && (
        <DeleteMember
          yesAction={() => setShow(false)}
          noAction={() => setShow(null)}
          text={`You’re about to cancel your current payout ${
            user?.getPaidInCrypto ? "crypto" : "setup"
          }?`}
          subtext={`If you do, please select other option to receive your payouts.`}
          noneclass="d-none"
        />
      )}
      {showOtpPopUp?.show && (
        <UpdateAndVerifyAccountOTP
          setShowOtpPopUp={setShowOtpPopUp}
          callApi={callApi}
        />
      )}
    </>
  );
}

export default Payment;
