import React, { useState, useEffect } from "react";
import useApi from "../../helpers/apiHelper/requestHelper";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

const LeaderComunityTradeInfo = () => {
  const [communityBinanceTrade, setCommunityBinanceTrade] = useState([]);
  const [communityBybitTrade, setCommunityBybitTrade] = useState([]);
  const [binanceApiLoading, setBinanceApiLoading] = useState(false);
  const [bybitApiLoading, setBybitApiLoading] = useState(false);
  const [errorResult, setErrorResult] = useState([]);
  const api = useApi();
  const user = useSelector((value) => value?.user?.user);

  const getComunityActiveTradeBinance = async () => {
    setBinanceApiLoading(true);
    const binance = await api(
      "post",
      "SubAccount/getComunityActiveTradeBinance"
    );
    setCommunityBinanceTrade(binance?.data);
    setBinanceApiLoading(false);
  };

  const getComunityActiveTradeBybit = async () => {
    setBybitApiLoading(true);
    const bybit = await api("post", "SubAccount/getComunityActiveTradeBybit");
    setCommunityBybitTrade(bybit?.data);
    setBybitApiLoading(false);
  };

  const errrResults = async () => {
    if (!user?.leaderCommunity?._id) return;
    const res = await api("post", "community/getFailedTrades", {
      community: user?.leaderCommunity?._id,
    });
    setErrorResult(res?.data);
  };

  useEffect(() => {
    getComunityActiveTradeBinance();
    getComunityActiveTradeBybit();
  }, []);

  useEffect(() => {
    errrResults();
  }, [user]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "user.email",
        header: "Email",
        size: 150,
        Cell: ({ row }) => {
          const email = row?.original?.user?.email;
          return <>{email}</>;
        },
      },
      {
        accessorKey: "position.symbol",
        header: "Asset",
        size: 150,
        Cell: ({ row }) => {
          const asset = row?.original?.position?.symbol;
          return <>{asset}</>;
        },
      },
      {
        accessorKey: "position.side",
        header: "Direction",
        size: 200,
        Cell: ({ row }) => {
          const side = row?.original?.position?.side;
          return <>{side}</>;
        },
      },
      {
        accessorKey: "position.updatedTime",
        header: "Open",
        size: 150,
        Cell: ({ row }) => {
          const time =
            row?.original?.position?.updatedTime ||
            row?.original?.position?.updateTime;

          const createdTime = new Date(parseInt(time));

          const formattedTime = createdTime.toLocaleString("en-US", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            year: "numeric",
            month: "numeric",
            day: "numeric",
          });
          return <>{formattedTime}</>;
        },
      },
    ],
    []
  );

  const errorTablesColumns = useMemo(
    () => [
      {
        accessorKey: "community",
        header: "Community Id",
        size: 150,
        Cell: ({ row }) => {
          const community = row?.original?.community;
          return <>{community}</>;
        },
      },
      {
        accessorKey: "exchange",
        header: "Exchange",
        size: 150,
        Cell: ({ row }) => {
          const exchange = row?.original?.exchange;
          return <>{exchange}</>;
        },
      },
      {
        accessorKey: "reason",
        header: "Error Message",
        size: 350,
        Cell: ({ row }) => {
          const reason = row?.original?.reason;
          return <>{reason}</>;
        },
      },
      {
        accessorKey: "symbol",
        header: "Symbol",
        size: 150,
        Cell: ({ row }) => {
          const symbol = row?.original?.symbol;
          return <>{symbol}</>;
        },
      },
      {
        accessorKey: "user",
        header: "User",
        size: 150,
        Cell: ({ row }) => {
          const user = row?.original?.user?.email;
          return <>{user}</>;
        },
      },
    ],
    []
  );

  const commnTableProps = {
    enableFacetedValues: true,
    initialState: {
      density: "compact",
    },
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableGlobalFilter: false,
    enableColumnActions: false,
  };

  const binanceActiveTradeTable = useMaterialReactTable({
    columns,
    data: communityBinanceTrade || [],
    ...commnTableProps,
    state: {
      isLoading: binanceApiLoading,
      showProgressBars: binanceApiLoading,
    },
  });

  const bybitActiveTradeTable = useMaterialReactTable({
    columns,
    data: communityBybitTrade || [],
    ...commnTableProps,
    state: {
      isLoading: bybitApiLoading,
      showProgressBars: bybitApiLoading,
    },
  });

  const errorTable = useMaterialReactTable({
    columns: errorTablesColumns,
    data: errorResult || [],
    ...commnTableProps,
  });

  return (
    <div className="container mt-5">
      <h3>Active Community Trades in Binance</h3>
      <MaterialReactTable table={binanceActiveTradeTable} />
      <h3 style={{ marginTop: "30px" }}>Active Community Trades in Binance</h3>
      <MaterialReactTable table={bybitActiveTradeTable} />
      <h3 style={{ marginTop: "30px" }}>Failed Community Trades</h3>
      <MaterialReactTable table={errorTable} />
    </div>
  );
};

export default LeaderComunityTradeInfo;
