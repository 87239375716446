import React, { useEffect, useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import delete_icon from "../../../assets/images/close_danger.svg";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import { toastify } from "../../../helpers/toast/toastify";
// import { api } from "../../../helpers/apiHelper/requestHelper";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { updateUser } from "../../../redux/users/usersSlice";
import { setLoading } from "../../../redux/loading/loadingSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import Button from "../../../components/button/Button";

export default function LeaderSubscribers() {
  const api = useApi();
  const [show, setShow] = useState(0);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState({ message: "", subject: "" });
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedSubscription, setSelectedSubscription] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const dispatch = useDispatch();
  const participants = useSelector(
    (state) => state?.user?.user?.leaderCommunity?.participants
  );
  const leaderCommunity = useSelector(
    (state) => state?.user?.user?.leaderCommunity
  );
  const sendMessage = async () => {
    if (selected?.length === 0) toastify("Users are missing.");
    else if (!data?.subject) missingPopup("Subject");
    else if (!data?.message) missingPopup("Message");
    else {
      const response = await api("post", "users/sendMessage", {
        ...data,
        users: selected,
      });

      if (response?.status) {
        toastify("Emails sent successfully.", "success");
        setData({ message: "", subject: "" });
        setSelected([]);
      } else {
        toastify(response?.message);
      }
    }
  };

  useEffect(() => {
    if (participants) {
      setUsers(participants?.map((e) => e.user));
    }
  }, [participants]);

  const yesAction = async () => {
    dispatch(setLoading(true));
    const response = await api("post", "users/unsubscribe", {
      sessionId: selectedSubscription,
      communityId: leaderCommunity._id,
      userId: selectedUser,
      leader: true,
    });

    if (response?.status) {
      toastify("User unsubscribed successfully.", "success");
      dispatch(updateUser(response?.data?.user));
    } else {
      toastify(response?.message);
    }

    dispatch(setLoading(false));

    setSelectedSubscription("");
    setSelectedUser("");
    setShowModal(false);
  };

  const noAction = () => {
    setShowModal(false);
  };

  return (
    <div
      className="row justify-content-center h-100 support_wrapper"
      style={{ padding: "0px 36px" }}
    >
      {showModal && (
        <ConfirmationModal
          yesAction={yesAction}
          noAction={noAction}
          text="You really want to cancel subscriber?"
        />
      )}
      <div className="col-lg-10 disable_animation_accordion d-flex flex-column">
        <div className="page_title d-sm-flex align-items-sm-center justify-content-sm-between">
          <h4 className="fs25 text_GB">Manage subscribers</h4>
          <ul className="tabs_list fs18 pt-sm-0 pt-4 d-flex align-items-center list-unstyled">
            <li
              className="cursor-pointer"
              aria-expanded={show === 0}
              onClick={() => setShow(0)}
            >
              Message
            </li>
            <li
              className="cursor-pointer"
              aria-expanded={show === 1}
              onClick={() => setShow(1)}
            >
              Cancel subscriber
            </li>
          </ul>
        </div>
        {show === 0 ? (
          <div className="accordion-collapse show collapse h-100">
            <div className="h-100 d-flex flex-column ">
              <div className="page_form d-grid gap-4 mt-sm-5 mt-3 pt-sm-4 pt-3 pb-sm-5">
                <div className="row d-grid gap-3">
                  <div className="col-sm-12">
                    <div>
                      <Multiselect
                        className="form-control multiselect"
                        options={
                          selected?.length === users?.length
                            ? users
                            : users?.concat({
                                email: "Select All",
                              })
                        }
                        selectedValues={selected}
                        onSelect={(e) => {
                          let found = e.find((f) => f.email === "Select All");
                          if (found) {
                            setSelected(users);
                            return;
                          }
                          setSelected(e);
                        }}
                        onRemove={(e) => setSelected(e)}
                        displayValue="email"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control fs15"
                      placeholder=""
                      value={data?.subject}
                      onChange={(e) =>
                        setData({
                          ...data,
                          subject: e.target.value,
                        })
                      }
                      maxLength={100}
                    />
                  </div>
                  <div className="col-sm-12">
                    <textarea
                      rows="6"
                      placeholder="Type your message here"
                      className="form-control fs15 resize_null"
                      value={data?.message}
                      onChange={(e) =>
                        setData({
                          ...data,
                          message: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                <Button name="SEND" handleClick={sendMessage} />
              </div>
            </div>
          </div>
        ) : (
          <div className="accordion-collapse collapse h-100 show">
            <div className="h-100 d-flex flex-column">
              <div className="page_form d-grid gap-4 mt-sm-5 mt-3 pt-sm-4 pt-3">
                {participants?.length > 0 ? (
                  <div className="ps-2 pe-sm-5 pe-2">
                    <input
                      type="text"
                      placeholder="Search email"
                      className="form-control fs15 rounded-0 border-dark border-end-0 border-start-0 border-top-0 px-0 py-1 min_hAuto"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                ) : (
                  <div className="ps-2 pe-sm-5 pe-2 py-3 text-center">
                    <h6 className="bold">No Subscribers</h6>
                  </div>
                )}
                <div
                  className="mt-3 table-responsive scrollbars"
                  // style="height: 572px"
                >
                  <table className="table table-light align-middle">
                    <tbody>
                      {users
                        ?.filter((e) =>
                          e.email
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        ?.map((e, i) => (
                          <tr key={i}>
                            <td>
                              <div className="px-2 fs15">{e.email}</div>
                            </td>
                            <td className="d-flex align-items-center justify-content-end py-3">
                              <button
                                className="btn_delete border-0 bg-transparent py-1"
                                onClick={() => {
                                  let _community = e.memberCommunities?.find(
                                    (f) =>
                                      f.community ===
                                      leaderCommunity._id.toString()
                                  );

                                  setSelectedSubscription(_community.sessionId);
                                  setSelectedUser(e._id);

                                  setShowModal(true);
                                }}
                              >
                                <img src={delete_icon} alt="img" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
