import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/images/search-icon.svg";
import { Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDetectClickOutside } from "react-detect-click-outside";
// import Pagination from "../../../components/Pagination";
import xmark from "../../../assets/images/xmark.svg";
import useApi from "../../../helpers/apiHelper/requestHelper";
import RenderLinks from "../../../components/links/RenderLinks";
import NoDataGif from "../../../assets/images/no_data.gif";
import CustomPagination from "../../../components/CustomPagination";
import {
  capitalizeFirstLetter,
  formatTime,
  simplifyTradingVolume,
} from "../../../helpers/helper";
import Table from "react-bootstrap/Table";

export default function TradeHistory() {
  const api = useApi();
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [history, setHistory] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const ref = useDetectClickOutside({ onTriggered: () => setShow(false) });
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const filterHistory = () => {
      let filtered = [...history];

      if (selectedCommunities.length > 0) {
        filtered = filtered.filter((e) =>
          selectedCommunities.includes(e.community)
        );
      }

      if (startDate) {
        filtered = filtered.filter(
          (e) =>
            new Date(startDate).toLocaleDateString() ===
            new Date(e?.timestamp).toLocaleDateString()
        );
      }

      setFilteredHistory(filtered);
    };

    filterHistory();
  }, [selectedCommunities, startDate, history]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Check the screen size on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getPercentage = (value) => {
    let final = parseFloat(value);

    if (isNaN(final)) {
      return "0.00";
    }

    if (final === "-0.00") {
      return "0.00";
    } else {
      return final.toFixed(2);
    }
  };

  useEffect(() => {
    const getTradeHistory = async () => {
      const response = await api("get", "users/getTradeHistory");
      if (response?.status) {
        setHistory(response?.data);
      }
    };

    getTradeHistory();
  }, []);

  return (
    <div className="row justify-content-center h-100 p-top-on-mobile">
      <div className="col-lg-12">
        <div
          className="connection"
          style={{ paddingTop: isMobile ? "0px" : "42px" }}
        >
          <div className=" mx-auto mt-30">
            <div className="page_title d-flex align-items-center justify-content-between flex-nowrap mb-3">
              <h2 className="tradinghistporyTitle mt-30">Closed Trades</h2>
              <div className="custom-dropdown d-flex" ref={ref}>
                <button
                  className="dropdown-toggle"
                  onClick={() => setShow(!show)}
                >
                  <img
                    className="filter-icon"
                    alt="searchIcons"
                    src={searchIcon}
                  />
                </button>

                {show && (
                  <div className="dropdown-menu">
                    <Accordion defaultActiveKey={""}>
                      <div className="clear-all align-items-center">
                        <text>Filters</text>
                        <span
                          onClick={() => {
                            setStartDate();
                            setSelectedCommunities([]);
                          }}
                        >
                          Clear All
                        </span>
                      </div>
                      <Accordion.Item eventKey="2" className="filterAccordian">
                        <Accordion.Header>Trade Date</Accordion.Header>
                        <Accordion.Body className="d-flex align-items-center justify-content-around">
                          <DatePicker
                            className="px-2 py-2 mt-2"
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                            }}
                            placeholderText="Select Date"
                            maxDate={new Date()}
                          />
                          <span
                            className="ms-2 cursor-pointer"
                            onClick={() => setStartDate()}
                          >
                            <img className="xmark" src={xmark} alt="xmark" />
                          </span>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <li></li>
                  </div>
                )}
              </div>
            </div>
            <div className="tradeHistory">
              <Table striped className="billingTable TradeHistoryTable">
                <thead>
                  <tr>
                    <th className="text-start ps-3" style={{ width: "25%" }}>
                      Asset{" "}
                      <span className="d-lg-none d-md-none">& Direction</span>
                    </th>
                    <th className="text-start d-set" style={{ width: "25%" }}>Direction</th>
                    <th style={{ width: "25%" }}>Closed</th>
                    <th className="w-lg-200px pe-3" style={{ width: "25%" }}>PNL</th>
                  </tr>
                </thead>
                <tbody>
                  {(filteredHistory.length > 0 ? filteredHistory : history)
                    ?.filter((e) => {
                      if (startDate) {
                        if (
                          new Date(startDate).toLocaleDateString() ===
                          new Date(e?.timestamp).toLocaleDateString()
                        ) {
                          return e;
                        }
                      } else {
                        return e;
                      }
                    })
                    ?.filter((e, index) => {
                      if (index < page * 10 && index > page * 10 - 11) {
                        return e;
                      }
                    })
                    ?.map((item, index) => (
                      <tr key={index}>
                        <td className="text-start ps-3">
                          <p className="border-right text-truncate pb-set">
                            {item?.pair}
                          </p>
                          <p className="d-lg-none d-md-none pt-0">
                            {capitalizeFirstLetter(item?.side)}
                          </p>
                        </td>
                        <td className="text-start d-set">
                          <p className="border-right">
                            {capitalizeFirstLetter(item?.side)}
                          </p>
                        </td>
                        <td className="dark-text">
                          <p>
                            {(() => {
                              const date = new Date(item?.timestamp);
                              const day = date.getDate();
                              const month = date.getMonth() + 1; // Adding 1 because months are zero-based
                              const year = date.getFullYear();
                              return `${month}/${day}/${year}`;
                            })()}{" "}
                            <span>{formatTime(item?.timestamp)}</span>
                          </p>
                        </td>
                        <td className="pe-3 pe-lg-0 pe-md-0">
                          <div className="d-lg-flex d-md-flex align-items-center justify-content-start gap-2">
                            <div
                              className={`number d-flex align-items-center ${
                                parseFloat(
                                  parseFloat(item?.pnlPercentage || "0.00%")
                                ) < 0
                                  ? "active"
                                  : ""
                              }`}
                              // style={{ fontSize: "16px", paddingLeft: "7px" }}
                            >
                              <h4 style={{ minWidth: "75px" }}>
                                {simplifyTradingVolume(
                                  getPercentage(item?.pnlPercentage || "0.00")
                                )}
                                %
                              </h4>
                              {/* <span className="text-green ms-2 d-none d-lg-block d-md-block">
                                                                /
                                                            </span> */}
                            </div>
                            {/* <span className="text-green d-lg-block d-md-block d-none">
                                                            /
                                                        </span> */}
                            <div
                              className={`number ${
                                parseFloat(
                                  parseFloat(item?.realizedPnl || "0.00%")
                                ) < 0
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <h4>
                                $
                                {simplifyTradingVolume(
                                  getPercentage(item?.realizedPnl || "0.00")
                                )}
                              </h4>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  {/* Check if no data is available after filtering */}
                  {filteredHistory.length === 0 && history.length === 0 && (
                    <tr>
                      <td colSpan="4" className="text-center">
                        <p className="text-center">
                          There's no trading history yet
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="tradeHistoryMbl d-none">
              {(filteredHistory.length > 0 ? filteredHistory : history)
                ?.filter((e) => {
                  if (startDate) {
                    if (
                      new Date(startDate).toLocaleDateString() ===
                      new Date(e?.timestamp).toLocaleDateString()
                    ) {
                      return e;
                    }
                  } else {
                    return e;
                  }
                })
                ?.filter((e, index) => {
                  if (index < page * 10 && index > page * 10 - 11) {
                    return e;
                  }
                })
                ?.map((item, index) => (
                  <div className="card" key={index}>
                    <div className="content">
                      <div>
                        <span className="fs12 text-uppercase">
                          {item?.exchange}
                        </span>
                        <p className=" text-truncate">{item?.pair}</p>
                      </div>
                      <div className="dateShort">
                        <span>
                          {(() => {
                            const date = new Date(item?.timestamp);
                            const day = date.getDate();
                            const month = date.getMonth() + 1; // Adding 1 because months are zero-based
                            const year = date.getFullYear();
                            return `${month}/${day}/${year}`;
                          })()}
                        </span>
                        <p className="">{capitalizeFirstLetter(item?.side)}</p>
                      </div>
                    </div>
                    <span className="borderLine"></span>
                    <div>
                      <div
                        className={`number ${
                          parseFloat(
                            parseFloat(item?.pnlPercentage || "0.00%")
                          ) < 0
                            ? "active"
                            : ""
                        }`}
                        style={{ fontSize: "16px", paddingLeft: "7px" }}
                      >
                        <h4>
                          {simplifyTradingVolume(
                            getPercentage(item?.pnlPercentage || "0.00")
                          )}
                          %
                        </h4>
                      </div>
                      <div
                        className={`number ${
                          parseFloat(parseFloat(item?.realizedPnl || "0.00%")) <
                          0
                            ? "active"
                            : ""
                        }`}
                      >
                        <h4>
                          $
                          {simplifyTradingVolume(
                            getPercentage(item?.realizedPnl || "0.00")
                          )}
                        </h4>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {history.length > 10 && (
              <div className="d-flex justify-content-end mt-4">
                {/* <Pagination
                                    setPage={setPage}
                                    page={page}
                                    filtered={filteredHistory}
                                    items={history}
                                /> */}
                <CustomPagination
                  setPage={setPage}
                  page={page}
                  items={history}
                  filtered={filteredHistory}
                />
              </div>
            )}
          </div>
        </div>
        <RenderLinks page="performanceHistory" />
      </div>
    </div>
  );
}
