import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import Binance from "../../../assets/images/Binance_black.png";
import ByBit from "../../../assets/images/Bybit_black.png";
import CheckMark from "../../../assets/images/CheckMark.svg";
import CheckCircle from "../../../assets/images/CheckCircle.svg";
import { useSelector } from "react-redux";

const countries = [
  {
    value: "Binance Futures USDT Perpetual account",
    label: "Futures USDT Perpetual",
    icon: Binance,
    secondIcon: CheckMark,
  },
  {
    value: "Bybit Derivatives USDT Perpetual account",
    label: "Derivatives USDT Perpetual",
    icon: ByBit,
    secondIcon: CheckMark,
  },
  // {
  //     value: "Binance Futures Coin-m Perpetual account",
  //     label: "Futures Coin-m Perpetual",
  //     icon: Binance,
  //     secondIcon: CheckCircle,
  // },

  // {
  //     value: "Bybit Derivatives Inverse Perpetual account",
  //     label: "Derivatives Inverse Perpetual",
  //     icon: ByBit,
  //     secondIcon: CheckMark,
  // },
];

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <div className="d-flex align-items-center justify-content-between">
      <div className="logoDiv">
        <img src={props.data.icon} alt="logo" className="country-logo" />
        {props.data.label}
      </div>
      {/* <div className="checkImg">
                <img
                    src={props.data.secondIcon}
                    alt="second-logo"
                    className="checkCircle"
                />
            </div> */}
    </div>
  </components.Option>
);

function AutoShare({ onExchangeChange, initialValue }) {
  const [selectedCountry, setSelectedCountry] = useState();

  useEffect(() => {
    if (initialValue?.length > 0) {
      setSelectedCountry(
        initialValue?.length === 1 &&
          initialValue[0] === "Binance Futures USDT Perpetual account"
          ? countries[0]
          : initialValue?.length === 1 &&
            initialValue[0] === "Bybit Derivatives USDT Perpetual account"
          ? countries[1]
          : initialValue?.length === 2
          ? countries?.map((country) => country)
          : null
      );
    }
  }, []);

  const handleChange = (value) => {
    setSelectedCountry(value);
    if (onExchangeChange) {
      onExchangeChange(value);
    }
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {selectedCountry ? (
        <div className="d-flex align-items-center justify-content-between">
          <div className="logoDiv">
            <img
              src={selectedCountry.icon}
              alt="s-logo"
              className="selected-logo"
            />
            {children}
          </div>
          {/* <div className="checkImg">
                        <img
                            src={selectedCountry.secondIcon}
                            alt="second-logo"
                            className="checkCircle"
                        />
                    </div> */}
        </div>
      ) : (
        <div className="placeholder">Your New Placeholder Text</div>
      )}
    </components.SingleValue>
  );

  return (
    <React.Fragment>
      <div className="select onBorading">
        <Select
          value={selectedCountry}
          options={countries}
          onChange={handleChange}
          isSearchable={false}
          isMulti
          // menuIsOpen={true}
          placeholder="Select exchange account(s)"
          styles={{
            singleValue: (base) => ({
              ...base,
              display: "flex",
              alignItems: "center",
            }),
          }}
          components={{
            Option,
            SingleValue,
          }}
        />
      </div>
    </React.Fragment>
  );
}

export default AutoShare;
