import React, { useState } from "react";
import copyIcon from "../../assets/images/Copy.svg";
import Button from "../../components/button/Button";
import { toastify } from "../../helpers/toast/toastify";
import useApi from "../../helpers/apiHelper/requestHelper";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loading/loadingSlice";
import { updateUser } from "../../redux/users/usersSlice";
import { useEffect } from "react";

export default function LeaderSocialPlatforms() {
  const api = useApi();
  const dispatch = useDispatch();
  const [serverId, setServerId] = useState("");
  const [channelName, setChannelName] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [chatId, setChatId] = useState("");
  const [addTwitter, setAddTwitter] = useState(false);
  const user = useSelector((value) => value?.user?.user);

  const telegramChatId = user?.leaderCommunity?.socials?.telegramChatId;

  useEffect(() => {
    setChatId(user?.leaderCommunity?.socials?.telegramChatId);
  }, [user?.leaderCommunity?.socials]);

  const handleDiscord = async () => {
    if (!channelName) {
      toastify("Channel name is missing.", "error");
      return;
    }
    if (!serverId) {
      toastify("Server ID is missing.", "error");
      return;
    }

    const requestBody = {
      name: channelName,
      communityId: user?.leaderCommunity?._id,
      serverId,
    };

    dispatch(setLoading(true));

    const response = await api(
      "post",
      "social/createDiscordChannel",
      requestBody
    );
    if (response?.status) {
      toastify(response?.message, "success");
      dispatch(updateUser(response?.data));
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      toastify(response?.message, "error");
    }
    dispatch(setLoading(false));
  };

  const handleTelegram = async () => {
    dispatch(setLoading(true));
    if (!chatId) {
      toastify("Chat id is missing.", "error");
      dispatch(setLoading(false));
      return;
    }

    const requestBody = {
      chatId,
      communityId: user?.leaderCommunity?._id,
    };

    const response = await api("post", "social/verifyTelegram", requestBody);
    if (response?.status) {
      toastify(response?.message, "success");
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      toastify(response?.message, "error");
    }
  };

  const twitterOauth = async () => {
    dispatch(setLoading(true));
    if (user?.twitterCodeVerifier && user?.twitterRefreshToken && !addTwitter) {
      toastify("You have already Authorized with Twitter.", "error");
      dispatch(setLoading(false));
      return;
    }
    const response = await api("get", "social/twitterOAuth");
    if (response?.status) {
      window.location.assign(response?.data?.url);
      toastify(response?.message, "success");
      dispatch(setLoading(false));
    } else {
      dispatch(setLoading(false));
      toastify(response?.message, "error");
    }
  };

  const handleButtonClick = () => {
    // Replace the URL with your Discord OAuth URL
    const discordOAuthURL =
      "https://discord.com/api/oauth2/authorize?client_id=1178618297638469732&permissions=3089&scope=bot";

    // Open the URL in a new tab
    window.open(discordOAuthURL, "_blank");
  };

  const handleCopyyyItBotURL = () => {
    const CopyyyItBotURL = "https://t.me/CopyTrading_Results_bot";
    window.open(CopyyyItBotURL, "_blank");
  };

  return (
    <>
      {user?.firstVisit && (
        <div className="row justify-content-center h-100 px-5">
          <div className="col-lg-10 disable_animation_accordion d-flex flex-column">
            <div className="page_title d-sm-flex align-items-sm-center justify-content-sm-between">
              <h4 className="fs25 text_GB">Share your performance</h4>
            </div>
            <div className="d-flex flex-column">
              <p className="fs16 my-3">
                Please choose the social channels you want to automatically
                share your performance to. You’ll only be sharing when a trade
                is 100% closed. You won’t be spamming your group with partial
                closes (TP1, TP2) etc. <br /> Once you’ve added all your
                channels press ‘save’.
              </p>
              <React.Fragment>
                <div className="row">
                  <div className="col-12 mt-4 mb-2 d-flex flex-column justify-content-end">
                    <label className="fs16 text_GB">Select a Platform</label>
                    <select
                      className="w-100 form-control mt-1"
                      value={selectedPlatform}
                      onChange={(e) => setSelectedPlatform(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Platform
                      </option>
                      <option value="discord">Discord</option>

                      <option value="telegram">Telegram</option>
                      <option value="twitter">Twitter</option>
                    </select>
                  </div>

                  {selectedPlatform === "twitter" && (
                    <>
                      {user?.twitterCodeVerifier &&
                      user?.twitterRefreshToken ? (
                        <div className="d-flex align-items-center justify-content-center mt-5">
                          <div className="mb-3">
                            <div className="d-flex">
                              <div className="me-2">
                                <input
                                  id="usdCheckbox"
                                  type="checkbox"
                                  checked={addTwitter}
                                  onChange={(e) =>
                                    setAddTwitter(e.target.checked)
                                  }
                                />
                              </div>
                              <div className="mt-1">
                                <p className="fs16">
                                  You've already added Twitter OAuth. Would you
                                  like to add another account?
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p className="fs16 my-3">
                          You need to authorize copyyy.it to post on your
                          Twitter channel.
                        </p>
                      )}

                      <div className="col-12 d-flex flex-column justify-content-end mt-5">
                        <Button name="Authorize" handleClick={twitterOauth} />
                      </div>
                    </>
                  )}

                  {selectedPlatform === "telegram" && (
                    <>
                      <div className="col-12 mt-4 d-flex flex-column justify-content-end">
                        <label className="fs16 text_GB">
                          1. Connect with our bot
                        </label>
                        <p className="fs16">
                          The bot will guide you to fetch your ‘Chat ID’{" "}
                        </p>

                        {/* <p className="fs16 mt-2">
                                                (a) Click here to find it
                                            </p> */}

                        <div className="col-12 d-flex flex-column justify-content-end mt-4 mb-2">
                          <Button
                            name={telegramChatId ? "BOT CONNECTED" : "CONNECT"}
                            newStyle="btn bg-dark radius h_60 w_260 fs20 custom-btn mb-3 white-color"
                            exchange={true}
                            handleClick={handleCopyyyItBotURL}
                          />
                        </div>
                        {/* <div className="mt-3">
                                                <p className="fs16">
                                                    (b) Press start
                                                </p>
                                                <p className="fs16">
                                                    (c) Select ‘Channel’
                                                </p>
                                                <p className="fs16">
                                                    (d) Select the TG channel to
                                                    share results in and press
                                                    send
                                                </p>
                                                <p className="fs16">
                                                    (e) Copy the ‘Chat id’ from
                                                    the message TG sends you
                                                </p>
                                            </div> */}
                      </div>
                      <div className="col-12 mt-4 d-flex flex-column justify-content-end">
                        <label className="fs16 text_GB">
                          2. Add your ‘Chat ID’
                        </label>
                        <p className="fs16">
                          Add the 'Chat ID' you copied from the Telegram
                          message.
                        </p>
                        <input
                          type="text"
                          className="form-control fs15 mt-1"
                          value={chatId}
                          onChange={(e) => {
                            setChatId(e.target.value);
                          }}
                          placeholder="Enter chat id"
                        />
                      </div>
                      {chatId && (
                        <div className="col-12 d-flex flex-column justify-content-end mt-5">
                          <Button name="SAVE" handleClick={handleTelegram} />
                        </div>
                      )}
                    </>
                  )}

                  {selectedPlatform === "discord" &&
                    !user?.leaderCommunity?.socials?.discordChannelId && (
                      <>
                        <div className="col-12 mt-4 d-flex flex-column justify-content-end">
                          <label className="fs16">
                            1. Add a channel on your discord server where the
                            results should be shared to.
                          </label>
                          <input
                            type="text"
                            className="form-control fs15 mt-1"
                            value={channelName}
                            onChange={(e) => {
                              setChannelName(e.target.value);
                            }}
                            placeholder="Enter channel name"
                          />
                        </div>

                        <div className="col-lg-12 mt-2 mt-4 pt-lg-1 pt-1">
                          <div className="row">
                            <div className="col-12 d-flex flex-column justify-content-end">
                              <label className="fs16">
                                2. Connect our bot to your discord server
                              </label>
                            </div>
                            <div className="col-12 d-flex flex-column justify-content-end mt-4 mb-2">
                              <Button
                                name="CONNECT"
                                exchange={true}
                                newStyle="btn bg-dark radius h_60 w_260 fs20 custom-btn mb-3 white-color"
                                handleClick={handleButtonClick}
                              />
                            </div>

                            <div className="col-12 mt-4 d-flex flex-column justify-content-end">
                              <label className="fs16">
                                3. Once connected please share the server ID
                              </label>
                              <input
                                className="form-control fs15 mt-1"
                                value={serverId}
                                onChange={(e) => {
                                  setServerId(e.target.value);
                                }}
                                placeholder="Enter Server ID"
                              />
                            </div>

                            {serverId && (
                              <div className="col-12 d-flex flex-column justify-content-end mt-4">
                                <Button
                                  name="SAVE"
                                  handleClick={handleDiscord}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  {selectedPlatform === "discord" &&
                    user?.leaderCommunity?.socials?.discordChannelId && (
                      <>
                        <div className="col-12 d-flex flex-column justify-content-end mt-3">
                          <label className="fs15">Channel Name</label>
                          <div className="mt-2 col-sm-12 col-lg-12 col-md-12 position-relative d-flex align-items-center justify-content-end">
                            <input
                              type="text"
                              className="form-control fs15 bold"
                              value={
                                user?.leaderCommunity?.socials
                                  ?.discordChannelName
                              }
                              disabled
                            />
                            <span className="position-absolute me-2">
                              <img
                                className="cursor-pointer me-2"
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(
                                      user?.leaderCommunity?.socials
                                        ?.discordChannelName
                                    )
                                    .then(() => {
                                      toastify(
                                        "Discord Channel Name copied to clipboard!",
                                        "success"
                                      );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Clipboard write error:",
                                        error
                                      );
                                      toastify(
                                        "Failed to Discord Channel Name to clipboard.",
                                        "error"
                                      );
                                    });
                                }}
                                src={copyIcon}
                              />
                            </span>
                          </div>
                        </div>

                        <div className="col-12 d-flex flex-column justify-content-end mt-3">
                          <label className="fs15">Channel URL</label>
                          <div className="mt-2 col-sm-12 col-lg-12 col-md-12 position-relative d-flex align-items-center justify-content-end">
                            <input
                              type="text"
                              className="form-control fs15 bold"
                              value={
                                user?.leaderCommunity?.socials
                                  ?.discordChannelUrl
                              }
                              disabled
                            />
                            <span className="position-absolute me-2">
                              <img
                                className="cursor-pointer me-2"
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(
                                      user?.leaderCommunity?.socials
                                        ?.discordChannelUrl
                                    )
                                    .then(() => {
                                      toastify(
                                        "Discord Channel URL copied to clipboard!",
                                        "success"
                                      );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Clipboard write error:",
                                        error
                                      );
                                      toastify(
                                        "Failed to Discord Channel URL to clipboard.",
                                        "error"
                                      );
                                    });
                                }}
                                src={copyIcon}
                              />
                            </span>
                          </div>
                        </div>

                        <div className="col-12 d-flex flex-column justify-content-end mt-3">
                          <label className="fs15">Channel ID</label>
                          <div className="mt-2 col-sm-12 col-lg-12 col-md-12 position-relative d-flex align-items-center justify-content-end">
                            <input
                              type="text"
                              className="form-control fs15 bold"
                              value={
                                user?.leaderCommunity?.socials?.discordChannelId
                              }
                              disabled
                            />
                            <span className="position-absolute me-2">
                              <img
                                className="cursor-pointer me-2"
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(
                                      user?.leaderCommunity?.socials
                                        ?.discordChannelId
                                    )
                                    .then(() => {
                                      toastify(
                                        "Discord Channel ID copied to clipboard!",
                                        "success"
                                      );
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Clipboard write error:",
                                        error
                                      );
                                      toastify(
                                        "Failed to copy Discord Channel ID to clipboard.",
                                        "error"
                                      );
                                    });
                                }}
                                src={copyIcon}
                              />
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
