import React, { useEffect, useState } from "react";
import copy_icon from "../../../assets/images/Copy.svg";
import addIcon from "../../../assets/images/addIcon.png";
import AddDiscountModal from "../../../components/modals/AddDiscountModal";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { updateUser } from "../../../redux/users/usersSlice";
import editIcon from "../../../assets/images/edit_icon.svg";

export default function LeaderReferralCodes() {
  const [discounts, setDiscounts] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedCode, setSelectedCode] = useState();
  const user = useSelector((state) => state.user.user);
  const api = useApi();
  const dispatch = useDispatch();

  const community = user?.leaderCommunity;

  const handleAccept = async (code, percentage) => {
    dispatch(setLoading(true));
    if (selectedCode) {
      const response = await api("post", "discount/update", {
        id: selectedCode?._id,
        code,
        percentage,
      });
      if (response?.status) {
        toastify("Discount code updated successfully", "success");
        // setDiscounts(response?.data);
        getDiscounts();
        setShow(false);
        setSelectedCode();
      } else {
        toastify(response?.message);
      }
    } else {
      const response = await api("post", "discount/create", {
        code,
        percentage,
        community,
      });
      if (response?.status) {
        toastify("Discount code created successfully", "success");
        setDiscounts(response?.data);
        setShow(false);
      } else {
        toastify(response?.message);
      }
    }
    dispatch(setLoading(false));
  };

  const getDiscounts = async () => {
    const response = await api("get", "discount/index");
    if (response?.status) {
      setDiscounts(response?.data);
    } else {
      toastify(response?.message);
    }
  };

  useEffect(() => {
    getDiscounts();
  }, []);

  const changeIsActive = async (isActive, discount) => {
    dispatch(setLoading(true));

    const response = await api("post", "discount/update", {
      isActive,
      id: discount._id,
    });
    if (response?.status) {
      getDiscounts();
      dispatch(setLoading(false));
    } else {
      toastify(response?.message);
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="row justify-content-center h-100 px-5">
      {show && (
        <AddDiscountModal
          noAction={() => {
            setShow(false);
            setSelectedCode();
          }}
          yesAction={handleAccept}
          discount={selectedCode}
        />
      )}
      <div className="col-lg-10 d-flex flex-column">
        <div className="page_title">
          <h4 className="fs25 text_GB">Discount codes</h4>
        </div>
        <div className="h-100 d-flex flex-column">
          <div className="page_form d-grid gap-4 mt-sm-5 mt-3 pt-sm-4 pt-3">
            <div className="d-flex align-items-center">
              <h4 className="fs16 text_GB">
                Create a discount code
                {/* Add discount codes here for first month membership for community
                member that signs up. */}
              </h4>
              {discounts?.length === 0 && (
                <img
                  src={addIcon}
                  className="ms-4 addIcon"
                  onClick={() => setShow(true)}
                />
              )}
            </div>
            <div className="row">
              {discounts?.map((d, i) => (
                <div className="d-flex my-2 row">
                  <div className="mt-3 col-xl-4 col-lg-8 col-md-12 col-sm-12 col-12 position-relative d-flex align-items-center justify-content-end">
                    <input
                      type="text"
                      className="form-control fs15"
                      value={d.code}
                      disabled
                    />

                    <span className="position-absolute me-3">
                      <img
                        src={copy_icon}
                        alt="img"
                        className="cursor-pointer"
                        onClick={() => {
                          navigator.clipboard.writeText(`${d?.code}`);
                          toastify(
                            "Discount code copied to clipboard!",
                            "success"
                          );
                        }}
                      />
                    </span>
                  </div>

                  <div className="mt-3 col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                    <input
                      type="text"
                      className="form-control fs15 "
                      value={d.percentage + "%"}
                      disabled
                    />
                  </div>

                  <div className="mt-3 d-flex align-items-center justify-content-between col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 ">
                    <div
                      className="d-flex align-items-center justify-content-center"
                      // style={{ zIndex: -1 }}
                    >
                      <input
                        id="1"
                        type="checkbox"
                        checked={d.isActive || false}
                        onChange={(e) => changeIsActive(e.target.checked, d)}
                      />
                      <label for="1" className="ms-2">
                        Activate Code
                      </label>
                    </div>
                    <img
                      src={editIcon}
                      className="cursor-pointer ms-1"
                      onClick={() => {
                        // edit(d);
                        setSelectedCode(d);
                        setShow(true);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            {/* <p>
                            Read more about our referral program{" "}
                            <a href="#" className="text-dark">
                                <u className="text_GB">here </u>
                            </a>
                        </p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
